<app-sidebar></app-sidebar>
<app-header></app-header>
<header>
    <div class="header-container border_btm">
        <div class="row hideSearchOpen">
            <div class="col-md-11 col-11 col-sm-11">
                <div class="mobile_nav_icons">
                    <i id="mobilenav" (click)="openNav()" class="fa-solid fa-bars"></i>
                    <div class="mobile-view-icons">
                        <div class="filter_mobile" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
                            (click)="displayMenu3()">
                            <i class="fa-solid fa-sliders"></i>
                        </div>
                        <div class="notificationheader" routerLink="/notifications">
                            <img route src="assets/images/Notification.png" alt="" class="notification">
                        </div>
                    </div>
                    <div class="search">
                        <div id="form-inline-id" class="form-inline">
                            <form [formGroup]="searchForm" id="new_group" (ngSubmit)="submit_handler('inputSearch')">
                                <i class="fa-solid fa-magnifying-glass search_icon"></i>
                                <input autocomplete="off" id="inputSearch" #inputSearch class="" type="input"
                                    placeholder="{{'Search for files' | translate}}" aria-label="Search"
                                    formControlName="searchInput">
                            </form>
                            <img route src="assets/images/cross.png" alt="" class="" id="close-search">
                            <button class="filterButton" id="filter_Button" #qryButton3
                                (click)="displayMenu3()">{{'Filter' | translate}}</button>
                            <button class="btn btn-search my-2 my-sm-0" id="search_button"
                                (click)="submit_handler('inputSearch')">{{'Search' | translate}}</button>
                        </div>
                    </div>
                </div>
                <div class="mobile_search_field1">
                    <form [formGroup]="searchForm" (ngSubmit)="submit_handler('inputSearch2')">
                        <div class="form-inline">
                            <i class="fa-solid fa-arrow-left search_icon" (click)="goBack()"></i>
                            <input class="" id="inputSearch2" #inputSearch2 type="input"
                                placeholder="{{'Search for files' | translate}}" aria-label="Search"
                                formControlName="searchInput">
                            <img route src="assets/images/cross.png" alt="" class="" id="close-search2"
                                (click)="clearSearchInput()" *ngIf="searchForm.get('searchInput').value !== ''">
                            <button class="btn btn-search my-2 my-sm-0" id="search_button2" type="submit"><i
                                    class="fa-solid fa-magnifying-glass "></i></button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="columns main-columns-div h-100">
                <div class="main overflow w-100" [ngClass]="{'mainoverDiv': showSideBar}">
                    <div class="searchSection" 
                        *ngIf="(serchedDatas && serchedDatas.length > 0) ||  (serchedDataFolder && serchedDataFolder.length > 0) || (serchedDataProject && serchedDataProject.length > 0) ||(!filterSection) ">
                        <div class="archive_div"><button class="btn btnBulkcancel" *ngIf="showCheckBox" (click)="deselectAll()">{{'Cancel' | translate}}</button><button class="btn btnBulkselect" *ngIf="showCheckBox" (click)="selectAll()">{{'Select All' | translate}}</button><button class="btn btnBulkdelete"  *ngIf="(projectFilesID.length !== 0) && (currentValue && currentValue.permissions.archiveDelete)"
                            (click)="openArchieveModal()">{{'Archive' | translate}}</button></div>
                        <div class="d-flex ">
                            <div class="sub_title_container" *ngIf="serchedDatas && serchedDatas.length > 0">
                                <div class="title_md d-flex align-items-center"><img src="assets/images/document_grey.png" alt="" class="title_sm_logo">{{'Files' | translate}}</div>
                            </div>
                            
                        </div>
     
                        <div class="files-card mt-4">
                            <div class="files" *ngFor="let data of serchedDatas index as i"
                                (click)="getImagePreview(data)"
                                (contextmenu)="open($event, data); $event. preventDefault();">
                                
                                <div class="smText d-flex align-items-start">
                                    <img src="{{ data.preview_image }}" class="img-fluid" alt=""><span
                                        class="filesName">
                                        {{ data.file_name }}
                                    </span>
                                    <label class="checkbox-control">
                                        <input type="checkbox" [(ngModel)]="data.selected"
                                            (click)="$event.stopPropagation()" (change)="bulkDelete($event, data.uuid,i,data)"
                                            class="inputRadiobutton checkbox1" *ngIf="showCheckBox"><span
                                            class="checkbox-control__target">Card Label</span></label>
                                </div>
                                <!-- <div><img class="img-fluid w-100" src="{{data.preview_url}}" alt=""></div> -->
                                <div class="previewDiv"><img class="img-fluid w-100" src="{{data.preview_url}}" *ngIf="data.preview_url" alt=""></div>
                                <div  *ngIf="!data.preview_url"><img class="img-fluid w-100" src="assets/images/image_preview.png" alt=""></div>
                                <div class="mt-1 project_exceeded" *ngIf="data.checkout_status == 1 && data.checkout_access !== 1">
                                    <p class="text-danger" *ngIf="data.checkout_status == 1 && data.checkout_access !== 1">*{{'The following file have been checked out' | translate}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="sub_title_container" *ngIf="serchedDataFolder && serchedDataFolder.length > 0">
                            <div class="title_md d-flex align-items-center"><img src="assets/images/folder_grey.png"
                                    alt="" class="title_sm_logo">{{'Folders' | translate}}</div>
                        </div>
                        <div class="folder-card">
                            <div class="card1" (click)="openFolder(data,data.project_time_exceeded,data.checkout_status,data.checkout_access)" 
                                *ngFor="let data of serchedDataFolder index as i"
                                (contextmenu)="open($event, data); $event. preventDefault();">
                                <label class="checkbox-control" *ngIf="(showCheckBox)">
                                    <input type="checkbox" [(ngModel)]="data.selected"
                                        (click)="$event.stopPropagation()" (change)="bulkDelete($event, data.uuid,i,data)"
                                        class="inputRadiobutton2 checkbox" *ngIf="(showCheckBox) "><span
                                        class="checkbox-control__target">Card Label</span></label>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img class="mr-12" src="assets/images/folder.png" alt="">
                                    </div>
                                    
                                    <div>
                                        <div class="subText">{{ data.file_name}} <br></div>
                                        <!-- <span>{{ data.code }}</span> -->
                                        <div class="smText">{{data.file_count}} {{'Files' | translate}} ,
                                            {{data.folder_count}} {{'Folders' | translate}}</div>
                                        
                                    </div>
                                    
                                </div>
                                <!-- <div class="mt-1 project_exceeded" >
                                    <p class="text-danger" *ngIf="data.project_time_exceeded == 1">*{{'The timeline of following files have expired' | translate}}</p>
                                </div> -->
                                <div class="mt-1 project_exceeded" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">
                                    <p class="text-danger" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">*{{'The timeline of following files have expired' | translate}}</p>
                                  </div>
                                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">
                                    <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">*{{'The following folder have been checked out' | translate}}</p>
                                  </div>
                                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">
                                    <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">*{{'The timeline of following folder have expired and checked out' | translate}}</p>
                                  </div>
                                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">
                                    <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">*{{'The timeline of following files have expired' | translate}}</p>
                                  </div>
                                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded !== 1">
                                    <p class="text-danger">*{{'You have checked out this folder. Please return before' | translate}}</p>
                                  </div>
                            </div>
                        </div>
                        <div class="sub_title_container" *ngIf="serchedDataProject && serchedDataProject.length > 0">
                            <div class="title_md d-flex align-items-center"><img src="assets/images/wheel_doc.png"
                                    alt="" class="title_sm_logo">{{'Projects' | translate}}</div>
                        </div>

                        <div class="projects">
                            <div class="grid">
                                <div class="card1 p-15 cursor_pointer" *ngFor="let data of serchedDataProject index as i" (contextmenu)="opencontext($event, data); $event. preventDefault();"
                                    (click)="openProject(data.uuid,data.project_time_exceeded,data.checkout_status,data.checkout_access)">
                                    <!-- (click)="handleCheckboxClick($event)" -->
                                    <div>
                                        <label class="checkbox-control">
                                            <input type="checkbox" [(ngModel)]="data.selected"  (change)="bulkDelete($event, data.uuid,i,data)"
                                                class="inputRadiobutton2 checkbox" *ngIf="showCheckBox">
                                                <span class="checkbox-control__target">Card Label</span></label>
                                    </div>
                                    <div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="title_md d-flex">
                                                <div><img class="mr-12" src="assets/images/Union.png" alt=""></div>
                                                <div class="projectName-len">{{ data.name}}</div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mt-3 mb-3">
                                            <img class="" src="assets/images/elipse_grey.png" alt="">
                                            <div class="smText"> {{ data.file_count }} {{'Files' | translate}}, {{
                                                data.folder_count }} {{'Folders' | translate}}</div>
                                        </div>

                                    </div>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center" id="shared_user_id">
                                            <ul class="mb-0 ul_line mr-12" *ngIf="data.projectMembers">
                                                <li *ngFor="let img of data.projectMembers | slice:0:limit"
                                                    class="d-inline"><img class="picUserproject"
                                                        src="{{img.profile_pic}}" alt="" /></li>
                                            </ul>
                                            <img (click)="toShare(data.uuid, $event,data.name);$event. preventDefault()" class="cursor_pointer"
                                                src="assets/images/addUser.png" alt="" />
                                        </div>
                                        <h6 class="blue_letter">{{data.size}}</h6>
                                    </div>
                                    
                                    <div class="mt-1 project_exceeded" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0" >
                                        <p class="text-danger" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">*{{'Your project timeline has been exceeded' | translate}}</p>
                                      </div>
                                      <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">
                                        <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">*{{'The following project have been checked out' | translate}}</p>
                                      </div>
                                      <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">
                                        <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">*{{'Your project timeline has been exceeded and checked out' | translate}}</p>
                                    </div>
                                    <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">
                                      <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">*{{'Your project timeline has been exceeded' | translate}}</p>
                                  </div>
                                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded !== 1">
                                    <p class="text-danger">*{{'You have checked out this folder. Please return before' | translate}}</p>
                                  </div>
                                    <div>
                                        <img class="mr-12 dots" src="assets/images/more_sm2.png" alt=""
                                            (click)="opencontext($event, data); $event. preventDefault()">
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="modal" id="deleteModal3" aria-hidden="true"
                            aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                            <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal_header">
                                        <h5>{{'Delete' | translate}} ?</h5>
                                        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="text-center">{{'Do you want to delete' | translate}}</div>
                                    </div>
                                    <div id="delete_id" class="btnGrp mt-4">
                                        <button id="button-id" class="btn_primary bg_blue" #closebutton
                                            data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
                                        <button id="button-id" class="btn_primary bg_red"
                                            (click)="deleteProject()">{{'Delete' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="filterSection" *ngIf="filterSection">
                        <div>
                            <div class="filter_div_report">
                                
                                <div class="btnGrp_filter" *ngIf="(filteredData && filteredData.length > 0) ||  (filterFolder && filterFolder.length > 0) || (filterProjects && filterProjects.length > 0) || (filterSection) ">
                                    <a *ngIf="hideButtonPath !== null" rel="noopener noreferrer"
                                        class="btn btn-primary download-btn" id="report_slip_upload" target="_blank"
                                        [href]="path_url" (click)="logPathUrl()">{{'Reports1'|
                                        translate}}<img class="download-img" src="assets/images/download_oprtion.png"
                                            alt=""></a>
                                    <button class="btn btnBulkcancel ml-2" *ngIf="showCheckBox" (click)="deselectAll()">{{'Cancel' | translate}}</button>
                                    <button class="btn btnBulkselect" *ngIf="showCheckBox" (click)="selectAll()">{{'Select All' | translate}}</button>
                                    <button class="btn btnBulkdelete" *ngIf="projectFilesID.length !== 0"
                                        (click)="openArchieveModal()">{{'Archive' | translate}}</button>

                                </div>
                            </div>
                            <div class="sub_title_container" *ngIf="filteredData && filteredData.length > 0">
                                <div class="title_md d-flex align-items-center"><img
                                src="assets/images/document_grey.png" alt="" class="title_sm_logo">{{'Files' | translate}}</div>
                            </div>
                            
                            <div class="files-card ">
                                <div class="files" *ngFor="let data of filteredData index as i"
                                    (click)="getImagePreview(data,'1')"
                                    (contextmenu)="open($event, data); $event. preventDefault();">
                                    
                                    <div class="smText d-flex align-items-start mt-0">
                                        <img src="{{ data.preview_image }}" class="img-fluid" alt=""><span
                                            class="filesName">{{ data.file_name }}</span>
                                            <label class="checkbox-control">
                                                <input type="checkbox" [(ngModel)]="data.selected"
                                                    (click)="$event.stopPropagation()"
                                                    (change)="bulkDelete($event, data.uuid,i,data)"
                                                    class="inputRadiobutton checkbox1" *ngIf="showCheckBox"><span
                                                    class="checkbox-control__target">Card Label</span></label>
                                    </div>
                                    <!-- <div class="mt-3"><img class="img-fluid w-100" src="{{data.preview_url}}" alt="">
                                    </div> -->
                                    <div class="previewDiv"><img class="img-fluid w-100" src="{{data.preview_url}}" *ngIf="data.preview_url" alt=""></div>
                                    <div  *ngIf="!data.preview_url"><img class="img-fluid w-100" src="assets/images/image_preview.png" alt=""></div>
                                    <div class="mt-1 project_exceeded" *ngIf="data.checkout_status == 1 && data.checkout_access !== 1">
                                        <p class="text-danger" *ngIf="data.checkout_status == 1 && data.checkout_access !== 1">*{{'The following file have been checked out' | translate}}</p>
                                    </div>
                                </div>
                                
                                <div class="modal" id="deleteModal3" aria-hidden="true"
                                    aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                                    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal_header">
                                                <h5>{{'Delete' | translate}} ?</h5>
                                                <button type="button" #closebutton class="btn-close"
                                                    data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="text-center">{{'Do you want to delete' | translate}}</div>
                                            </div>
                                            <div id="delete_id" class="btnGrp mt-4">
                                                <button id="button-id" class="btn_primary bg_blue" #closebutton
                                                    data-bs-dismiss="modal" aria-label="Close">{{'Cancel' |
                                                    translate}}</button>
                                                <button id="button-id" class="btn_primary bg_red"
                                                    (click)="deleteProject()">{{'Delete' | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            <div class="sub_title_container" *ngIf="filterFolder && filterFolder.length > 0">
                                <div class="title_md d-flex align-items-center"><img src="assets/images/folder_grey.png"
                                        alt="" class="title_sm_logo">{{'Folders' | translate}}</div>
                            </div>
                            <div class="folder-card">
                                <div class="card1" (click)="openFolder(data,data.project_time_exceeded,data.checkout_status,data.checkout_access)"
                                    *ngFor="let data of filterFolder index as i"
                                    (contextmenu)="open($event, data); $event. preventDefault();">
                                    <label class="checkbox-control" *ngIf="showCheckBox">
                                        <input type="checkbox" [(ngModel)]="data.selected"
                                            (click)="$event.stopPropagation()"
                                            (change)="bulkDelete($event, data.uuid,i,data)"
                                            class="inputRadiobutton2 checkbox" *ngIf="showCheckBox"><span
                                            class="checkbox-control__target">Card Label</span></label>
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <img class="mr-12" src="assets/images/folder.png" alt="">
                                        </div>
                                        <div>
                                            <div class="subText">{{ data.folder_name}} <br></div>
                                            <!-- <span>{{ data.code }}</span> -->
                                            <div class="smText">{{data.file_count}} {{'Files' | translate}} ,
                                                {{data.folder_count}} {{'Folders' | translate}}</div>
                                        </div>
                                    </div>
                                    <!-- <div class="mt-1 project_exceeded" >
                                        <p class="text-danger" *ngIf="data.project_time_exceeded == 1">*{{'The timeline of following files have expired' | translate}}</p>
                                    </div> -->
                                    <div class="mt-1 project_exceeded" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0" >
                                        <p class="text-danger" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">*{{'The timeline of following files have expired' | translate}}</p>
                                      </div>
                                      <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1" >
                                        <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">*{{'The following folder have been checked out' | translate}}</p>
                                      </div>
                                      <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">
                                        <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">*{{'The timeline of following folder have expired and checked out' | translate}}</p>
                                      </div>
                                      <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">
                                        <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">*{{'The timeline of following files have expired' | translate}}</p>
                                      </div>
                                      <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded !== 1">
                                        <p class="text-danger">*{{'You have checked out this folder. Please return before' | translate}}</p>
                                      </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="sub_title_container" *ngIf="filterProjects && filterProjects.length > 0">
                                <div class="title_md d-flex align-items-center"><img src="assets/images/wheel_doc.png"
                                        alt="" class="title_sm_logo">{{'Projects' | translate}}</div>
                            </div>
                            <div class="projects">
                                <div class="grid">
                                    <div class="card1 p-15 cursor_pointer"
                                        *ngFor="let data of filterProjects index as i"
                                        (click)="openProject(data.uuid,data.project_time_exceeded,data.checkout_status,data.checkout_access)"
                                        (contextmenu)="opencontext($event, data); $event. preventDefault();">
                                        <label class="checkbox-control">
                                            <input type="checkbox" [(ngModel)]="data.selected"
                                                (click)="$event.stopPropagation()"
                                                (change)="bulkDelete($event, data.uuid,i,data)"
                                                class="inputRadiobutton2 checkbox" *ngIf="showCheckBox"><span
                                                class="checkbox-control__target">Card Label</span></label>
                                        <div>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="title_md d-flex">
                                                    <div><img class="mr-12" src="assets/images/Union.png" alt=""></div>
                                                    <div class="projectName-len">{{ data.name}}</div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center mt-3 mb-3">
                                                <img class="" src="assets/images/elipse_grey.png" alt="">
                                                <div class="smText"> {{ data.file_count }} {{'Files' | translate}}, {{
                                                    data.folder_count }} {{'Folders' | translate}}</div>
                                            </div>

                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="d-flex align-items-center" id="shared_user_id">
                                                <ul class="mb-0 ul_line mr-12">
                                                    <li *ngFor="let img of data.projectMembers | slice:0:limit"
                                                        class="d-inline"><img class="picUserproject"
                                                            src="{{img.profile_pic}}" alt="" /></li>
                                                </ul>
                                                <img (click)="toShare(data.uuid, $event,data.name)" class="cursor_pointer"
                                                    src="assets/images/addUser.png" alt="" />
                                            </div>
                                            <h6 class="blue_letter">{{data.size}}</h6>
                                        </div>
                                        <!-- <div class="mt-3 project_exceeded" *ngIf="data.project_time_exceeded == 1">
                                            <p class="text-danger" *ngIf="data.project_time_exceeded == 1">*{{'Your
                                                project
                                                timeline has been exceeded' | translate}}</p>
                                        </div> -->
                                        <div class="mt-1 project_exceeded" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0" >
                                            <p class="text-danger" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">*{{'Your project timeline has been exceeded' | translate}}</p>
                                          </div>
                                          <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">
                                            <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">*{{'The following project have been checked out' | translate}}</p>
                                          </div>
                                          <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">
                                            <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">*{{'Your project timeline has been exceeded and checked out' | translate}}</p>
                                        </div>
                                        <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">
                                          <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">*{{'Your project timeline has been exceeded' | translate}}</p>
                                      </div>
                                        <div>
                                            <img class="mr-12 dots" src="assets/images/more_sm2.png" alt=""
                                                (click)="opencontext($event, data); $event. preventDefault();$event.stopPropagation();">
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>



                    <div *ngIf="((serchedDatas && serchedDatas.length == 0) && (serchedDataProject && serchedDataProject.length == 0) && (serchedDataFolder && serchedDataFolder.length == 0) 
                    && (filteredData && filteredData.length == 0 )&& (filterProjects && filterProjects.length == 0) && (filterFolder && filterFolder.length == 0)) "
                            class="text-center userNotFound mt-5">
                            <img src="assets/images/nosearch.jpg" class="img-fluid mb-2">
                            <p>{{'No Files Found' | translate}}</p>
                    </div>


                </div>


                <aside class="sidebar-second" id="preview_info"
                    [ngClass]="{'width-side-second': showSideBar == true}">
                    <img class="close_storage" *ngIf="showSideBar" (click)="openSideMenu1('close')"
                        src="assets/images/arrow_right.png" alt="">
                    <div>
                        <div class="d-flex align-items-center justify-content-between" *ngIf="showSideBar">
                            <div
                                class="heading_1 w-100 d-flex align-items-center justify-content-center position-relative">
                                <h4 class="text-center">{{'Preview' | translate}}</h4>
                                <i _ngcontent-bnd-c3="" class="fa-solid fa-xmark closeNav"
                                    (click)="openSideMenu1('close')"></i>
                            </div>
                        </div>

                        <div class="preview_img d-flex justify-content-center align-items-center"
                            *ngIf="viewDetailsList">
                            <img src="{{viewDetailsList.file_url}}" alt="" class="img-fluid">
                        </div>

                        <div class="size-col" *ngIf="viewDetailsList">
                            <div class="">
                                <img src="{{viewDetailsList.file_image}}" alt="">
                                <span>{{viewDetailsList.name}}</span>
                            </div>

                            <h5>{{viewDetailsList.file_size}}</h5>
                        </div>
                        <div class="tabs">
                            <div class="position-relative tabOption cursor_pointer" data-tab="tab-1" id="infoShow"
                                (click)="show('info')">
                                <h6>{{'Info' | translate}}</h6>
                                <div class="line" [ngClass]="{'lineExtent': infoSection == true}"></div>
                            </div>
                            <div class="position-relative tabOption cursor_pointer" data-tab="tab-2" id="activityShow"
                                *ngIf="storageType && storageType.user_type == '2'" (click)="show('activity')">
                                <h6>{{'Activity' | translate}}</h6>
                                <div class="line" [ngClass]="{'lineExtent': activitySection == true}"></div>
                            </div>
                        </div>
                        <div class="tabs_contents">
                            <div class="info_content tab-content" *ngIf="infoSection">
                                <div class="text_sm">{{'Who can access' | translate}}</div>
                                <div class="d-flex justify-content-between info-1 margin-lr14">
                                    <div class="d-flex justify-content-between align-items-center w-100 share-option">
                                        <button class="share" (click)="toShare()">
                                            <img src="assets/images/upload-sm.png" alt="">
                                            {{'Share' | translate}}
                                        </button>
                                        <div class="users" id="shared_user_id">
                                            <ul>
                                                <li *ngFor="let img of viewDetailsList2 index as i" class="d-inline">
                                                    <img class="ms-1" src="{{img.profile_pic}}" alt="">
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="text_sm">{{'File properties' | translate}}</div>
                                <form [formGroup]="metadataForm">
                                    <div class="margin-lr14 info-2 details-div" *ngIf="viewDetailsList">
                                        <div class="grid">
                                            <div class="grid-child">
                                                <h5 class="black">{{'Saved location' | translate}}</h5>
                                            </div>
                                            <div class="grid-child">
                                                <h5 class="text-capitalise">{{viewDetailsList.saved_location}}</h5>
                                            </div>
                                            <div class="grid-child">
                                                <h5 class="black">{{'Size' | translate}}</h5>
                                            </div>
                                            <div class="grid-child">
                                                <h5>{{viewDetailsList.file_size}}</h5>
                                            </div>
                                            <div class="grid-child">
                                                <h5 class="black">{{'Modified' | translate}}</h5>
                                            </div>
                                            <div class="grid-child">
                                                <h5>{{viewDetailsList.modified}}</h5>
                                            </div>
                                            <div class="grid-child">
                                                <h5 class="black">{{'Type' | translate}}</h5>
                                            </div>
                                            <div class="grid-child">
                                                <h5>{{viewDetailsList.type}}</h5>
                                            </div>
                                            <div class="grid-child" *ngIf="viewDetailsList.priority_name">
                                                <h5 class="black">{{'Priority' | translate}}</h5>
                                            </div>
                                            <div class="grid-child" *ngIf="viewDetailsList.priority_name">
                                                <h5>{{viewDetailsList.priority_name}}</h5>
                                            </div>

                                        </div>
                                        <div class="row timeline-div1">
                                            <div class="timeline-firstdiv">
                                                <h5 class="black">{{'Timeline' | translate}}</h5>
                                                <button class="btn btn-edit edit-metadata-text" *ngIf="(NometadataforFolder == 1)" (click)="updatefolderTimeline('1')">{{'Edit' | translate}}</button>
                                            </div>
                                            <div class="timeline-div">
                                                <h5 class="h5-metadata"><p>{{viewDetailsList.start_date}}</p> &nbsp;<span>-</span>&nbsp;<p>{{viewDetailsList.end_date}}</p></h5>
                                            </div>
                                        </div>
                                        <div class="row metadata-sec" [ngClass]="{'d-none': !showSideBar}"
                                            *ngIf="!(NometadataforFolder == 1)">
                                            <div class="d-flex parent-div">
                                                <div class="first-div">
                                                    <h5 class="black">{{'Metadata' | translate}}</h5>
                                                </div>
                                                <div class="second-div" (click)="enableSelect()"
                                                    [hidden]="isButtonHidden"><button
                                                        class="btn btn-edit edit-metadata-text">{{'Edit' |
                                                        translate}}</button></div>
                                            </div>

                                            <div class="select-div">
                                                <mat-form-field class="custom-form-field3" appearance="none">
                                                    <mat-select
                                                        (selectionChange)="onSelectionChangee($event.value, 'key', 'preview')"
                                                        [(value)]="metadataId" [disabled]="isDisabled"
                                                        placeholder="{{'Select Template' | translate}}">
                                                        <mat-option value="" disabled selected>{{'Select Template' |
                                                            translate}}</mat-option>
                                                        <mat-option [value]="template.uuid"
                                                            *ngFor="let template of templateList">{{
                                                            template.template_name}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <div class="outer-div-metadata mt-3" *ngIf="!keyHide">
                                                    <ng-container formArrayName="metadatadetails">
                                                        <div *ngFor="let item of z.value;let i = index"
                                                            [hidden]="z.value[0] == ''">
                                                            <div [formGroupName]="i">
                                                                <div class="bottom-padd black mt-3 metadata_alignment">
                                                                    {{item.keyword_label}}</div>
                                                                <div class="border-filedvalue">
                                                                    <input class="ng-inpuut-field"
                                                                        *ngIf="item.field_type == '2'"
                                                                        formControlName="{{item.id}}"
                                                                        [readonly]="isReadOnly">
                                                                    <mat-form-field
                                                                        class="example-full-width metadata-loopsec"
                                                                        appearance="none"
                                                                        *ngIf="item.field_type == '1'">
                                                                        <input matInput [matDatepicker]="dp31"
                                                                            placeholder="{{'Select date' | translate}}"
                                                                            formControlName="{{item.id}}" readonly
                                                                            [disabled]="isDatePickerDisabled">
                                                                        <mat-datepicker-toggle matIconSuffix
                                                                            [for]="dp31"></mat-datepicker-toggle>
                                                                        <mat-datepicker #dp31></mat-datepicker>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="mt-3 btn_grp_metadata">
                                                    <button type="submit" class="btn btnnn bg-light text-dark"
                                                        [hidden]="isButtonHiddenCancel"
                                                        (click)="cancelButton()">{{'Cancel' | translate}}</button>
                                                    <button type="submit" class="btn btn-primary"
                                                        (click)="getMetadataDetails()"
                                                        [hidden]="isButtonHiddenSubmitt">{{'Update' |
                                                        translate}}</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="activity_content tab-content" *ngIf="activitySection">
                                <div *ngFor="let activity of activityDetails index as i">
                                    <div class="d-flex align-items-center margin-lr14 shared_1">
                                        <img src="{{activity.profile_pic}}" alt="">
                                        <div class="user_text">
                                            {{activity.message}}
                                            <h6>{{activity.created_at}}</h6>
                                        </div>
                                    </div>
                                    <div class="line"></div>
                                </div>
                                <div class="noactivity" *ngIf="activityDetails && activityDetails.length == 0">
                                    {{'There is no activity against this file.' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </div>
        </div>

        <div class="modal fade" id="deleteModalforArchieve_open" aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal_header">
                        <h5>{{'Archive' | translate}} ?</h5>
                        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="text-center text-danger">*{{'Your project timeline has been exceeded. Do you want archive' | translate}} ? </div>
                    </div>
                    <div id="delete_id" class="btnGrp mt-4">
                        <button id="button-id" class="btn_primary bg_blue" (click)="updateTimeline()">{{'Update Timeline' | translate}}</button>
                        <button id="button-id" class="btn_primary bg_red" (click)="openArchieveModal()">{{'Archive' |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="deleteModalforArchieve_project" aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel" tabindex="-1">
            <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal_header">
                        <h5>{{'Archive' | translate}} ?</h5>
                        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="text-center">{{'Do you want to archive ?' | translate}} </div>
                    </div>
                    <div id="delete_id" class="btnGrp mt-4">
                        <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal"
                            aria-label="Close">{{'Cancel' | translate}}</button>
                        <button id="button-id" class="btn_primary bg_red" (click)="restoreLater()">{{'Archive' |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="modal fade" id="searchNullModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal_header">
                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>

            <div class="modal-body">
                <div id="fileFound">
                    <i class="fa-solid fa-circle-exclamation"></i>
                    <div class="data">{{'No Files Found' | translate}}</div>
                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #overlayMenuList>
    <div class="filter filterRoute">
        <h5>{{'Filter by types' | translate}}</h5>
        <form [formGroup]="profileForm">
            <div class="selectGrp mt-4">
                <div class="options">
                    <label for="">{{'Project' | translate}}</label>
                    <div class="selct-filter">
                        <mat-form-field class="custom-form-field2" appearance="none">
                            <mat-select formControlName="project_id" [(value)]="selectedProject"
                                placeholder="{{'Select project' | translate}}">
                                <!-- <mat-option  [value]="null" disabled selected >{{'Select project' | translate}}</mat-option> -->
                                <mat-option [value]="project.id" *ngFor="let project of projectList">{{ project.name |
                                    translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                </div>
                <div class="options mrr">
                    <label for="">{{'Templates' | translate}}</label>
                    <!-- <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedTemplate" formControlName="template_id" (change)="onSelectionChange($event.target.value)">
                            <option disabled hidden selected  value="null">{{'Select Template' | translate}}</option>
                            <option *ngFor="let template of templateList" [value]="template.uuid">{{template.template_name | translate}}</option>
                        </select> -->
                    <div class="selct-filter">
                        <mat-form-field class="custom-form-field2" appearance="none">
                            <mat-select [(ngModel)]="selectedTemplate" placeholder="{{'Select Template' | translate}}"
                                formControlName="template_id" (selectionChange)="onSelectionChange($event)">
                                <input class="searchinput" placeholder="{{'Select Template' | translate}}"
                                    (keyup)="onKey($event.target.value)">
                                <!-- <mat-option  [value]="null" disabled selected >{{'Select Template' | translate}}</mat-option> -->
                                <mat-option [value]="template.uuid" *ngFor="let template of templateList">{{
                                    template.template_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>

            </div>
            <div class="selectGrp mt-4">
                <div class="options">
                    <label for="">{{'Priority' | translate}}</label>
                    <!-- <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedTag" formControlName="tag_id">
                            <option disabled hidden selected  value="null">{{'Select tag' | translate}}</option>
                            <option *ngFor="let tag of tagList" [value]="tag.id">{{tag.tag | translate}}</option>
                        </select> -->
                    <div class="selct-filter">
                        <mat-form-field class="custom-form-field2" appearance="none">
                            <mat-select placeholder="{{'Select Priority' | translate}}" formControlName="tag_id">
                                <mat-option value="1">{{ 'High'| translate }}</mat-option>
                                <mat-option value="2">{{'Medium' | translate}}</mat-option>
                                <mat-option value="3">{{'Low' | translate}}</mat-option>
                                <mat-option value="{{item.id}}" *ngFor="let item of priority">{{item.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
                <div class="options mrr">
                    <label for="">{{'Project Type' | translate}}</label>
                    <!-- <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedTemplate" formControlName="template_id" (change)="onSelectionChange($event.target.value)">
                            <option disabled hidden selected  value="null">{{'Select Template' | translate}}</option>
                            <option *ngFor="let template of templateList" [value]="template.uuid">{{template.template_name | translate}}</option>
                        </select> -->
                    <div class="selct-filter">
                        <mat-form-field class="custom-form-field2" appearance="none">
                            <mat-select [(ngModel)]="selectedProjectType"
                                placeholder="{{'Select Project type' | translate}}" formControlName="project_type_id">
                                <!-- <mat-option  [value]="null" disabled selected >{{'Select Project type' | translate}}</mat-option> -->
                                <mat-option [value]="types.id" *ngFor="let types of ProjectTypeslist">{{ types.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
            </div>

            <div class="metadata_form mt-3" *ngIf="templateDetails">
                <ng-container formArrayName="metadatadetails">
                    <div class="input-container" *ngFor="let item of templateDetails;let i = index" [formGroupName]="i">
                        <label class="label-text">{{ item.keyword_label | translate}}</label>
                        <div class="input_style date_input" *ngIf="item.field_type == '1'">
                            <mat-form-field class="example-full-width" appearance="none">
                                <input matInput [matDatepicker]="dp3" [matDatepicker]="picker"
                                    placeholder="{{'Select date' | translate}}" [(ngModel)]="item.date"
                                    formControlName="{{ item.id }}" readonly>
                                <mat-datepicker-toggle matIconSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker #dp3></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="input_style" *ngIf="item.field_type == '2'">
                            <div class="position-relative">
                                <input type="text" class="input" placeholder="" name="" id=""
                                    placeholder="{{item.keyword_label | translate}}" formControlName="{{ item.id }}">
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- <div class="mt-3 options">
                <div class="input_style mt-4 d-flex align-items-center date-pickker">
                    <div class="datePicker">
                        <div>
                            <label for="">{{'Starts from' | translate}}</label>
                            <mat-form-field class="example-full-width" appearance="none">
                                <input matInput [matDatepicker]="dp10" [matDatepicker]="picker" class="dateSearch"
                                    placeholder="{{'Select start date' | translate}}" readonly
                                    formControlName="project_start_date">
                                <mat-datepicker-toggle matIconSuffix [for]="dp10"></mat-datepicker-toggle>
                                <mat-datepicker #dp10></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div>
                            <img class="minus" src="assets/images/minus.png" alt="">
                        </div>
                        <div>
                            <div>
                                <label for="">{{'Ends on' | translate}}</label>
                                <mat-form-field class="example-full-width" appearance="none">
                                    <input matInput [matDatepicker]="dp2" [matDatepicker]="picker" class="dateSearch"
                                        placeholder="{{'Select end date' | translate}}" readonly
                                        formControlName="project_end_date" [min]="b.project_start_date.value">
                                    <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
                                    <mat-datepicker #dp2></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="mt-5">
                <h5>{{'Select by Timeline' | translate}}</h5>
                <div class="slider mt-2">
                    <input type="range" class="range" min="0" max="10" [value]="0" formControlName="timeline"
                        (input)="valueChanged($event)">
                    <span id="slideroutput" class="slider-value">{{ rangevalue }}</span>
                </div>
            </div>
            <div *ngIf="showMessage">
                <div class="error_message text-danger text-center">{{'Please select any of the filter options' | translate}}</div>
            </div>
            <div class="btnGrp">
                <button (click)="resetFiletrs()" class="btn1">{{'Reset all filters' | translate}}</button>
                <div class="one">
                    <div (click)="close()" class="btnCancel">{{'Cancel' | translate}}</div>
                    <button (click)="applyFilters()" class="btn-primary">{{'Apply Filters' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<app-move-modal></app-move-modal>

<ng-template #projectuserMenu let-user>
    <section class="user-menu ww" [hidden]="(user.project_time_exceeded == '1') && (!currentValue.permissions.archiveDelete)">
        <div *ngIf="user.project_time_exceeded == 0" (click)="toShare()"><img _ngcontent-ofp-c1="" alt=""
                src="assets/images/share_oprtion.png"> {{'Share' | translate}}</div>
        <div *ngIf="user.project_time_exceeded == 0 " (click)="toggleCheckboxVisibility()"><img _ngcontent-ofp-c1="" alt=""
                src="assets/images/select.png"> {{'Select' | translate}}</div>
        <div *ngIf="user.project_time_exceeded == 0 && (!this.showCheckBox)" (click)="getlinkModal()"><img _ngcontent-ofp-c1="" alt=""
                src="assets/images/url_oprtion.png"> {{'Get Link' | translate}}</div>
        <div *ngIf="user.project_time_exceeded == 0 && (!this.showCheckBox)" (click)="renamee()"><img _ngcontent-ofp-c1="" alt=""
                src="assets/images/edit_oprtion.png"> {{'Rename' | translate}}</div>
        <div (click)="deleteModal()"
            *ngIf="(currentValue && (currentValue.permissions.deleteProject)) && (user.project_time_exceeded == 0)"><img
                _ngcontent-ofp-c1="" alt="" src="assets/images/delete_oprtion.png"> {{'Delete' | translate}}</div>
        <div (click)="openArchieveModal()"
            *ngIf="currentValue &&(currentValue.permissions.archiveDelete) "><img
                _ngcontent-ofp-c1="" alt="" src="assets/images/recycle.png">{{'Add to archive' | translate}}</div>
        <div *ngIf="(!this.showCheckBox) && (user.project_time_exceeded == 0 && (user.checkout_status !== 1 && user.checkout_access !== 1))" (click)="checkoutModal()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check out' | translate}}</div>
        <div *ngIf="(!this.showCheckBox) && (user.project_time_exceeded == 0 && (user.checkout_status == 1 && user.checkout_access == 1))" (click)="checkoutModal('2')"><img _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check in' | translate}}</div>
    </section>
</ng-template>
<ng-template #userMenu let-user>
    <section class="user-menu qq" [hidden]="(user.project_time_exceeded == '1') && (!currentValue.permissions.archiveDelete)">
        <div (click)="openSideMenu(user)" *ngIf="(projectTYpee) && (user.project_time_exceeded == 0) && (!this.showCheckBox)"><img _ngcontent-ofp-c1=""
                alt="" src="assets/images/eye_oprtion.png"> {{'View Details' | translate}}</div>
        <div *ngIf="user.project_time_exceeded == 0" (click)="toShare()"><img _ngcontent-ofp-c1="" alt=""
                src="assets/images/share_oprtion.png"> {{'Share' | translate}}</div>
        <div *ngIf="user.project_time_exceeded == 0  "  (click)="toggleCheckboxVisibility()"><img _ngcontent-ofp-c1="" alt=""
                src="assets/images/select.png"> {{'Select' | translate}}</div>
        <div *ngIf="user.project_time_exceeded == 0 && (!this.showCheckBox)" (click)="getlinkModal()"><img _ngcontent-ofp-c1="" alt=""
                src="assets/images/url_oprtion.png"> {{'Get Link' | translate}}</div>
        <div *ngIf="(user.file_type== 2)" (click)="downloadFile()">
            <img _ngcontent-ofp-c1="" alt="" src="assets/images/download_oprtion.png"> {{'Download' | translate}}</div>

        <div (click)="getSignatureRequest()" *ngIf="(signature) && (user.project_time_exceeded == 0) && (!this.showCheckBox)"><img _ngcontent-ofp-c1=""
                alt="" src="assets/images/signicon.png"> {{'Get Signature' | translate}}</div>
        <div *ngIf="user.project_time_exceeded == 0 && (!this.showCheckBox)" (click)="rename()"><img _ngcontent-ofp-c1="" alt=""
                src="assets/images/edit_oprtion.png"> {{'Rename' | translate}}</div>
        <div (click)="moveProjectsFileorFolder()" *ngIf="(projectTYpee) && (user.project_time_exceeded == 0) && (filemove)"><img
                _ngcontent-ofp-c1="" alt="" src="assets/images/move_to_option.png"> {{'Move to'
            | translate}}</div>
        <div *ngIf="user.project_time_exceeded == 0" (click)="deleteModal()">
            <img _ngcontent-ofp-c1="" alt="" src="assets/images/delete_oprtion.png"> {{'Delete'
            | translate}}
        </div>
        <div *ngIf="(currentValue &&(currentValue.permissions.archiveDelete))"
            (click)="openArchieveModal()"><img _ngcontent-ofp-c1="" alt=""
                src="assets/images/recycle.png">{{'Add to archive' | translate}}</div>
        <!-- <div *ngIf="(!this.showCheckBox) && (user.project_time_exceeded == 0) " (click)="checkoutModal()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check out' | translate}}</div> -->
        <div *ngIf="(!this.showCheckBox) && (user.project_time_exceeded == 0 && (user.checkout_status !== 1 && user.checkout_access !== 1))" (click)="checkoutModal()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check out' | translate}}</div>
        <div *ngIf="(!this.showCheckBox) && (user.project_time_exceeded == 0 && (user.checkout_status == 1 && user.checkout_access == 1))" (click)="checkoutModal('2')"><img _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check in' | translate}}</div>
    </section>
</ng-template>

<div class="modal fade" id="timelineModal_open" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
            <h5>{{'Archive' | translate}} ?</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-center text-danger">*{{'The timeline of following files have expired' | translate}}.{{'Do you want archive' | translate}} ? </div>
        </div>
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id" class="btn_primary bg_blue"  (click)="updatefolderTimeline()">{{'Update Timeline' | translate}}</button>
          <button id="button-id" class="btn_primary bg_red"   (click)="openArchieveModal()">{{'Archive' | translate}}</button>
        </div>      
      </div>
    </div>
</div>





<div class="modal fade" id="folderEdit_modal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
            <h5>{{'Folder details' | translate}}</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form [formGroup]="timelineForm">
        <div class="modal-body">
  
          <div class="input_style mt-4">
                              
            <label for="exampleInputEmail1">{{'Folder Name' | translate}}</label>
            <input class="input" type="email" aria-describedby="emailHelp" placeholder="{{'Folder Name' | translate}}" readonly formControlName="name">
          </div>
          
          <div class="container_input_div">
            <div class="input_style mt-4 d-flex align-items-center">
                <div class="datePicker-modal">
                    <div class="dateDiv">
                        <label for="">{{'Starts from' | translate}}</label>
                        <mat-form-field class="example-full-width" appearance="none">
                            <input matInput [matDatepicker]="dp10"  [min]="todayDate" [matDatepicker]="picker" readonly   formControlName="start_date">
                            <mat-datepicker-toggle matIconSuffix [for]="dp10"></mat-datepicker-toggle>
                            <mat-datepicker #dp10></mat-datepicker>
                        </mat-form-field>
                        
                    </div>
                    <div>
                        <img class="minus" src="assets/images/minus.png" alt="">
                    </div>
                    <div class="dateDiv">
                        <div>
                            <label for="">{{'Ends on' | translate}}</label>
                            <mat-form-field class="example-full-width" appearance="none">
                                <input matInput [matDatepicker]="dp2" [matDatepicker]="picker" readonly   [min]="c.start_date.value" formControlName="end_date">
                                <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
                                <mat-datepicker #dp2></mat-datepicker>
                            </mat-form-field>
                            
                        </div>
                    </div> 
                </div>
            </div>
          </div>
  
  
        </div>
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id" class="btn_primary bg_red" (click)="closeTimelinemodal()" >{{'Cancel' | translate}}</button>
          <button id="button-id" class="btn_primary bg_blue"(click)="updateTimelinefolder()">{{'Update' | translate}}</button>
        </div>
      </form>    
      </div>
    </div>
</div>

<div class="modal fade" id="getLinkCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="renameCreateModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal_header">
        <h5>{{'Get Link' | translate}}</h5>
        <button type="button" #closebutton class="btn-close" (click)="closseGetlink()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div id="folderName_And_Tagsection">
          <form [formGroup]="getLinkForm">
            <div class="modal-body">
              <div class="input_style mt-1">
                <div class="input_style mt-1">
                    <label for="exampleInputEmail" class="titleNAME">{{'Comments' | translate}}</label>
                    <textarea name="" class="" id="" cols="30" rows="5" placeholder="{{'Comments' | translate}}" formControlName="comment"></textarea>
                </div>
                <!-- <div *ngIf="getLinkSubmitted && a.comment.errors" class="m-2">
                    <div class="error_message " *ngIf="a.comment.errors.required">{{'Comments' | translate}} {{'is required' | translate}}</div>
                    <div class="error_message" *ngIf="a.comment.errors.pattern">{{'Comments' | translate}} {{'shoud valid' | translate}}</div>
                </div> -->
              </div>
            </div>
            <div class="renameFileorFolder">
              <button type="button" class="btn_light text-primary renameBtn" (click)="closseGetlink()">{{'Cancel' | translate}}</button>
              <button class="btn_primary bg_blue renameBtn2" type="submit" (click)="getLink()">{{'Get Link' | translate}}</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>