import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiService } from '../../_services/api.services';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute , Router } from '@angular/router';
import { log } from 'console';

interface CustomOption {
  name: string;
  image: string;
  value:string;
}

@Component({
  selector: 'app-metadata-form',
  templateUrl: './metadata-form.component.html',
  styleUrls: ['./metadata-form.component.scss']
})
export class MetadataFormComponent implements OnInit {
  uuid: string | null;
  public metedataForm :FormGroup;
  public myForm: FormGroup;
  selected: any[];
  selected2: any[];
  options: CustomOption[] = [];
  addTemplateSubmit: boolean;
  response: any;
  templatesq: any;
  template_details: any;
  responseTemplateDetails: any;
  templateDetails: any;
  templateId: any;
  metadataType : any;

  constructor(public formBuilder: FormBuilder,
              private ngxUiLoaderService: NgxUiLoaderService,
              private api: ApiService,
              public snackBar : MatSnackBar,
              private router : Router,
              private route: ActivatedRoute) { 
                this.uuid = this.route.snapshot.paramMap.get('uuid');
                
                this.route.fragment.subscribe(fragment =>{
                  console.log(fragment);
                  this.metadataType = fragment;
                })
    this.options = [
      {
        "name": "Date picker",
        "image": "assets/images/Vector (3).png",
        "value" : "1"
      },
      {
        "name": "Text",
        "image": "assets/images/Vector (2).png",
        "value" : "2"
      },
      // {
      //   "name": "Misdsadsaddilaj",
      //   "image": "https://eflow.inflowdocs.com/assets/images/logolg2.png"
      // }
    ];
  }

  ngOnInit() {
    this.metedataForm = this.formBuilder.group({
      template_name: ['',[Validators.required,Validators.maxLength(32)]],
      template_details: this.formBuilder.array([this.newTemplate()],Validators.required) ,
    });
    this.route.params.subscribe(params => {
      this.uuid = params['id'];
      console.log("params value",this.uuid )

    })
    this.getTemplateDetails();
    this.myForm = this.newTemplate();
  }


  addTemplate() {
    console.log(this.metedataForm)
    this.addTemplateSubmit = true;
    if(this.metedataForm.status != "INVALID") {
      this.addTemplateSubmit = false;
      this.templates.push(this.newTemplate());
      console.log(this.metedataForm.controls)
    }
    console.log(this.template_details)
  }

  get templates() {
    return this.metedataForm.get("template_details") as FormArray;
    
  }

  get f() {
    return this.metedataForm.controls;
  }

  newTemplate():FormGroup {
    return this.formBuilder.group({
      keyword_label:['',[Validators.required]],
      keyword:['',[Validators.required]],
      field_type:['',[Validators.required]],

    })
  }


  changeClient(e,key) {
    if(key == 'a'){
      this.selected = e.value;
    }else {
      this.selected2 = e.value;
    }
  }

  onSubmitmetaData() {
    console.log(this.metedataForm)
    console.log('helo');
    this.addTemplateSubmit = true;
    if(this.metedataForm.invalid) {
      return
    }
   if(this.uuid){
    console.log(this.uuid);
    this.onSubmitmetaDataEdit();
   }else{
    this.onSubmitmetaDataCreate();
   }
  }
  
  
onSubmitmetaDataEdit() {
  this.ngxUiLoaderService.start();
  let data = {...this.metedataForm.value,
    template_id:this.templateId
  }
  if (this.metadataType == 1) {
    data.entitytype = 1
  } else {
    data.entitytype = 2
  } 
  
  console.log(data);
  this.api.onSubmitmetaData(data).subscribe(data=>{
  this.ngxUiLoaderService.stop();
  this.response = data;
  console.log(this.response)
  if(this.response.status == 200) {
    this.router.navigate(['/metadata'])
  } else {
  }
  this.snackBar.open(this.response.message, "Dismiss", {
    duration:3000,
  })
}, error => {
  this.ngxUiLoaderService.stop();
});
  }

onSubmitmetaDataCreate(){
  this.ngxUiLoaderService.start(); 
  let data ={...this.metedataForm.value,
  }

  if (this.metadataType == 1) {
    data.entitytype = 1
  } else {
    data.entitytype = 2
  } 
  
  console.log(data);
  this.api.onSubmitmetaData(data).subscribe(data=>{
    console.log(data);
    
  this.ngxUiLoaderService.stop();
  this.response = data;
  console.log(this.response)
  if(this.response.status == 200) {
    this.router.navigate(['/metadata'])
  } else {

  }
  this.snackBar.open(this.response.message, "Dismiss", {
    duration:3000,
  })
}, error => {
  this.ngxUiLoaderService.stop();
});
  }
  
  getTemplateDetails() {
    console.log('joo', this.uuid);
    this.api.getTemplateDetails(this.uuid).subscribe(data=>{
      this.ngxUiLoaderService.stop();
      console.log(data);
      this.responseTemplateDetails = data;
      console.log(this.responseTemplateDetails);
      if (this.responseTemplateDetails.status == 200) {
        console.log(this.responseTemplateDetails);
        this.templateDetails = this.responseTemplateDetails.template_details;
        this.templateId = this.templateDetails.template_details.id;
        console.log(this.templateId);
        
        console.log(this.templateDetails);
        
        this.metedataForm.get('template_name').setValue(this.templateDetails.template_details.template_name);
        for (let key1 in this.templateDetails.keyword) { 
          console.log(key1);
          
          if (key1 != '0') {
            this.templates.push(this.newTemplate());
          }
          this.templates.controls[key1].get('field_type').setValue(this.templateDetails.keyword[key1].field_type)
          this.templates.controls[key1].get('keyword_label').setValue(this.templateDetails.keyword[key1].keyword_label)
          this.templates.controls[key1].get('keyword').setValue(this.templateDetails.keyword[key1].keyword)
        }
        console.log(this.templates)
      }
    }, error => { 
      this.ngxUiLoaderService.stop();
    });
  } 

  deleteTemplate(index) {
    if (this.templates.length > 1) { // Ensure the array is not empty
      this.templates.removeAt(index);
    } else {
      console.error('Cannot remove the last item.');
    }
    console.log(this.templates)
  }

}
