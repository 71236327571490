import { Component, OnInit, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';
import { ApiService } from '../../_services/api.services';
import * as AOS from 'aos';
import 'aos/dist/aos.css';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Router, ActivatedRoute, Params } from '@angular/router';
// import faker from 'faker';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { fromEvent, Subscription } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { ModalService } from 'src/app/_services/modal.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { SharedService } from '../../_services/shared.service';
import * as moment from 'moment-timezone';
import {FileDownloadService} from '../../_services/download.service';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  myMediaQuery = window.matchMedia('(max-width: 1480px)');
  sub: Subscription;
  login: any;
  homeAdds: any;
  response: any;
  showSideBar = false;
 
  @ViewChild('userMenu', { static: false }) userMenu: TemplateRef<any>;
  //   @ViewChild('userMenu') userMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  // @ViewChild("chart", { static: false }) chart: ChartComponent;
  // public chartOptions: Partial<ChartOptions>;
  dashboardProjectlist: any;
  dashboardRecentfilelist: any;
  dashboardRecentfolderlist: any;
  dashboardRecentdetails: any;
  dashboardRecentStoragedetails: any;
  nameforFetch: string;
  responseProfile: any;
  responseProfileList: any;
  project_Id: any;
  copyProjectFileResponse: any;
  project: any;
  deleteResponse: any;
  projectResponse: any;
  moveTo = false;
  limit = 3;
  currentuserdetails: any;
  grouMembersuuid : any[]=[];
  grouMembers:any[]=[];
  responseActivelist: any;
  activeUserlist: any;
  groupSubmitted = false;
  file_Id: any;
  signatureRequestdetails:any;
  signature = false;
  restoreLaterResponse: any;
  archieveUuid: any;
  prjecTuuid: any;
  modalsubscription: any;
  timelineForm: FormGroup;
  folderdetailsdata: any;
  todayDate: any;
  timelineResponse: any;
  currentValue: any;
  shareName: any;
  shareuuid: any;
  downloadResponse: any;
  path_url: any;
  path_name: any;
  

  constructor(
    public overlay: Overlay,
    private api: ApiService,
    public viewContainerRef: ViewContainerRef,
    private ngxUiLoaderService: NgxUiLoaderService,
    public snackBar: MatSnackBar,
    private router: Router,
    private modalService: ModalService,
    public formBuilder: FormBuilder,
    private sharedService: SharedService,
    private fileDownloadService : FileDownloadService) {
    this.login = localStorage.getItem('login');
  }

  ngOnInit() {
    this.modalsubscription = this.modalService.ee.subscribe(data => {
      console.log(data)
      console.log('namaskaram');
      
      if (data == 'dashboard') {
        this.getDashboardDetails();
      } 
    });

    this.homeAdds = false;
    let lineBtn: any = document.getElementById('lineView');
    let gridBtn: any = document.getElementById('gridViewBtn');
    let table: any = document.getElementById('tableview');
    let gridview: any = document.getElementById('gridView');
    this.openStyle();
    this.getDashboardDetails();
    if (lineBtn) {
      lineBtn.addEventListener('click', () => {
        console.log('aAAA')
        table.style.display = 'block';
        gridview.style.display = 'none';
        lineBtn.style.cssText = 'filter: brightness(0) saturate(100%) invert(28%) sepia(50%) saturate(3236%) hue-rotate(216deg) brightness(93%) contrast(102%);';
        gridBtn.style.cssText = 'filter: grayscale(100%);';
      });

    }
    setTimeout(function () {
      AOS.init({ disable: 'mobile' });
    }, 100);
    this.currentuserdetails = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentuserdetails);

    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    console.log(this.currentValue)
    
    this.dataFormlocalStorage()

    this.timelineForm = this.formBuilder.group({
      name:[''],
      folderuuid:[''],
      start_date:[''],
      end_date:['']

    })
    this.project_Id = null;
  }
  get c(){
    return this.timelineForm.controls;
  }

  ngOnDestroy() {
    this.modalsubscription.unsubscribe(); 
  }
  dataFormlocalStorage() {
    let data = localStorage.getItem("currentUserDetails");
    console.log(data);
    let userDataObject = JSON.parse(data);
    if (typeof userDataObject.user_details.name === 'string') {
      console.log(typeof userDataObject.user_details.name);
      this.nameforFetch = userDataObject.user_details.name;
    } else {
      console.error("Name is not a string:", userDataObject.user_details.name);

    }
    console.log(this.nameforFetch);
  }


  openStyle() {
    let myMediaQuery = window.matchMedia('(max-width: 1480px)');
    let myMediaQuery2 = window.matchMedia('(max-width: 580px)');
    if (myMediaQuery.matches && $('#storageDetils').css('display') === 'block') {

      $('.recent_table').find('.t2 ').css({ 'display': 'none' });
      $('.recent_table').find('.t4 ').css({ 'display': 'none' });
      $('.recent_table').find('.dFlex').css({ 'display': 'none' });
      $('.recent_table').find('.t1').addClass('t1_change');
      $('.recent_table').find('.t3').addClass('t3_change');
    }


    else {
      $('.recent_table').find('.dFlex').css({ 'display': 'flex' });
      $('.recent_table').find('.t2 ').css({ 'display': 'block' });
      $('.recent_table').find('.t4 ').css({ 'display': 'block' });
      $('.recent_table').find('.t1').removeClass('t1_change');
      $('.recent_table').find('.t3').removeClass('t3_change');
    }
  }

  open(event: MouseEvent, user) {
    // Prevent the default context menu to avoid interference with your custom context menu
    console.log(event);
    event.preventDefault();
    event.stopPropagation();
    
    console.log(user, 'AAAAA');
    this.project_Id = user.uuid;
    this.shareName = user.name;
    console.log(this.project_Id);
    this.project = user;
    console.log(this.project);
    this.close();

    if( user.checkout_status == 1 && user.checkout_access == 0){
      return
    }

    if (this.project.move == 1) {
      this.moveTo = true;
    } else {
      this.moveTo = false;
    }
    if (this.project.preview_image && this.project.pdf_type == 1) {
      this.signature = true;
    } else {
      this.signature = false;
    }
    // if (this.folder.file_name) {
    //   if (this.currentValue.permissions.deleteFile) {
    //     this.delete = true;
    //   } else {
    //     this.delete = false;
    //   }
    // } else {
    //   if (this.currentValue.permissions.deleteFolder) { 
    //     this.delete = true;
    //   } else {
    //     this.delete = false;
    //   }
    // }
    //extra added for bottom breaking
    const isNearBottom = window.innerHeight - (event.clientY + window.scrollY) < 240;

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: event.x, y: event.y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: isNearBottom ? 'bottom' : 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));

    this.sub = fromEvent<MouseEvent>(document, 'mousedown')
      .pipe(
        filter(mouseEvent => {
          const clickTarget = mouseEvent.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }

  closeGroupmodal(){
    $('#createProjectModal').modal('hide');
    this.groupSubmitted = false;

  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  // toShare() {

  //   $('#shareModal').modal('show');
  //   this.close()
  // }
 
    
  toShare(uuid=null,event: MouseEvent = null,projectexceeded = null) {
    if(event){
      event.stopPropagation();
    }
    if(projectexceeded == '1'){
      return
    }else{
      this.close();
    console.log(this.project_Id);
    var data = {
      entity_type: 2,
      id: [this.project_Id],
      name:this.shareName
    }
    if (uuid) {
      data['id'] = [uuid.uuid];
      data['name'] = uuid.name;
    }
    this.modalService.openModal(data,'dashboard'); 
    }
  }
  
  showSearch() {
    console.log('JJJJ')
    $('.hideSearchOpen').css('display', 'none');
    $('.mobile_search_field').css('display', 'block');
  }

  deleteProjectFolderFile(){
    this.deleteProject();
    this. projectDeleteFolder();
  }

  deleteProject(){
    this.ngxUiLoaderService.start();
    this.api.deleteProject(this.project_Id).subscribe(data=>{
      console.log(this.project_Id);
      this.projectResponse = data;
      console.log(this.projectResponse,"2323232323232");
      if(this.projectResponse.status == 200){
        // console.log('file projectd');
        $('#deleteModal3').modal('hide')
        $(".modal-backdrop").remove();
        this.snackBar.open(this.projectResponse.message, "Dismiss", {
          duration:3000,
        })
        this.getDashboardDetails();
        this.ngxUiLoaderService.stop();
        
      }else{
        this.snackBar.open(this.projectResponse.message, "Dismiss", {
          duration:3000,
        })
        this.ngxUiLoaderService.stop();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  projectDeleteFolder() {

    this.ngxUiLoaderService.start();
    this.api.deleteFolderandFile(this.project_Id).subscribe(data => {
      console.log(data);
      this.deleteResponse = data;
      console.log(this.deleteResponse, "2323232323232");
      if (this.deleteResponse.status == 200) {
        $('#deleteModal3').modal('hide')
        $(".modal-backdrop").remove();
        // console.log('file deleted');
        this.snackBar.open(this.deleteResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.getDashboardDetails();
        this.ngxUiLoaderService.stop();


      } else {
        this.snackBar.open(this.deleteResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.ngxUiLoaderService.stop();
      }

    }, error => {
      this.ngxUiLoaderService.stop();
    });

  }

 
  deleteModal(){
    console.log('clicked');
    $('#deleteModal3').modal('show');
    this.close()
  }
  moveProjectsFileorFolder() {
    this.close();
    var data = {
      entity_type: 1,
      id: [this.project_Id],
      name:this.shareName
    }
    this.modalService.openModal(data, 'folder');
  }

  
  rename() {
    this.close();
    var data = {
      entity_type: 3,
      id: this.project_Id,
      data:this.project
    }
    console.log(data);
    this.modalService.openModal(data, 'dashboard');
  }

  getInfo(key: any) {
    console.log(key)
    if (key == '1') {
      this.showSideBar = false;
    }
    else {
      this.showSideBar = true;
    }

  }
  filter(key: any) {
    $('.uploadOptionsDrop').removeClass('drop')
    if (key == '1') {
      $(".card-1").css("z-index", "-1");
      $(".filter").css("display", "block");
      $(".notification_messages").css("display", "none");
    } else {
      $(".filter").css("display", "none");
      $(".notification_messages").css("display", "none");
      $(".profile_settings").css("display", "none");
    }
  }

 

  getDashboardDetails() {
    this.ngxUiLoaderService.start();
    this.api.getDashboardDetails().subscribe(data => {
      this.ngxUiLoaderService.stop();
      this.response = data;
      console.log(this.response)
      if (this.response.status == 200) {
        this.dashboardProjectlist = this.response.project_list;
        console.log(this.dashboardProjectlist);
        this.dashboardRecentfilelist = this.response.Recent_files;
        console.log(this.dashboardRecentfilelist);
        this.dashboardRecentfolderlist = this.response.Folder_list;
        console.log(this.dashboardRecentfolderlist);
        console.log(this.response);
        this.dashboardRecentdetails = this.response.details;
        console.log( this.dashboardRecentdetails);
        this.dashboardRecentStoragedetails = this.response.storage;
        console.log( this.dashboardRecentStoragedetails);
        
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  getroute(folder) {
    if (folder != 5) {
      let id = "folder1"
      this.router.navigate(['/folders', id])
    } else {
      let id = "folder5"
      this.router.navigate(['/folders', id])
    }
  }
  navigateWithState() {
    this.router.navigateByUrl('/profile-settings', { state: { hello: 'world' } });
  }

  // getAccounts() {
  //   this.api.getAccounts().subscribe(data => {
  //     this.responseProfile = data;
  //     console.log(this.responseProfile)
  //     if(this.responseProfile.status == 200) {
  //       this.ngxUiLoaderService.stop();
  //       this.responseProfileList = this.responseProfile.results.user_details;
  //       console.log(this.responseProfileList);

  //     }else {
  //       this.ngxUiLoaderService.stop();
  //       this.snackBar.open(this.responseProfile.message, "Alerte", {
  //         duration: 2000,
  //       });
  //     }

  //   },error => {
  //     this.ngxUiLoaderService.stop();
  //   });
  // }
  // getStorage(){
  //   this.chartOptions = {
  //     series: [44, 55, 13, 43, 22],
  //     chart: {
  //       type: "donut"
  //     },
  //     labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200
  //           },
  //           legend: {
  //             position: "bottom"
  //           }
  //         }
  //       }
  //     ]
  //   };
  // }
  openProject(uuid,timeline,checkout_status,checkout_access) {
    if(timeline == 1){
      console.log(timeline);
      console.log(uuid);
      this.prjecTuuid = uuid;
      $('#deleteModalforArchieve_open').modal('show');
    }else{
      if(checkout_status == 1 && checkout_access == 0){
        return
      }else{
        this.router.navigate(['/folders', uuid])
      }
      
    }
  }

  getImagePreview(image) {
    console.log(image);
    if (image) {
      var data = {
        entity_type: 4,
        data: image,
        list: this.dashboardRecentfilelist
      }
      this.modalService.openModal(data);
    }
   }

   copyProjectsFile() {
    this.close();
    var data = { file_id: this.project_Id }
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.copyProjectFile(data).subscribe(data => {
      console.log(data);
      this.copyProjectFileResponse = data;
      if (this.copyProjectFileResponse.status == 200) {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.copyProjectFileResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.getDashboardDetails()
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.copyProjectFileResponse.message, "Dismiss", {
          duration: 3000,
        })
      }

    }, error => {
      // this.ngxUiLoaderService.stop();
    });

  }
  getSignatureRequest() {
    this.getActiveUsers();
    this.grouMembers = [];
    this.grouMembersuuid = [];
    this.close();
  }


  getActiveUsers() {
    var data = {
      entityType : '1',
    }
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.getActiveUser(data).subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.responseActivelist = data;
      if (this.responseActivelist.status == 200) {
        this.activeUserlist = this.responseActivelist.results;
        console.log(this.activeUserlist);
        $('#createProjectModal').modal('show');
      }else{
        this.snackBar.open(this.responseActivelist.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  } 

  adduser(user){
    console.log('clicked');
    const isUserPresent = this.grouMembers.some(member => member.id === user.id);
    if (!isUserPresent) {
        user['notinuser'] = 1;
        this.grouMembers.push(user);
        this.grouMembersuuid.push(user.uuid);
        console.log(this.grouMembersuuid, '22222222222');
        if (this.grouMembers.length >= 1) {
            this.snackBar.open("Can't Add More Than One Group Member", "Dismiss", {
                duration: 3000,
            });
        }
    }
}


  removeUser(index: number, uuid, member) {
    console.log(member)
    this.grouMembersuuid = this.grouMembersuuid.filter(function(item) {
      // console.log(this.grouMembersuuid);
      return item !== uuid
    })
    console.log(this.grouMembersuuid)
    this.grouMembers.splice(index, 1);
  }

  ngSubmitrequestSugnature() {
    this.groupSubmitted = true;
    if(this.grouMembers.length == 0){
      console.log('asdsaddssdasasdsadadaddsadsdsad')
      return;
    }
    var data =  {
      receiver_user_id : this.grouMembersuuid,
      document: this.project_Id
      }
  
     console.log(data);
     this.ngxUiLoaderService.start();
     this.api.signatureRequest(data).subscribe(data=>{
      console.log(data);
      this.signatureRequestdetails = data;
      if(this.signatureRequestdetails.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.signatureRequestdetails);
        this.snackBar.open(this.signatureRequestdetails.message, "Dismiss", {
          duration:3000,
        })
        $('#createProjectModal').modal('hide');
        $(".modal-backdrop").remove();
        this.groupSubmitted = false;
        this.modalService.emit();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.signatureRequestdetails.message, "Dismiss", {
          duration:3000,
        })
      }
     }, error => {
      this.ngxUiLoaderService.stop();
        
      });
     
  }

  restoreLater(){
    this.close();
    if(this.prjecTuuid){
      this.project_Id = this.prjecTuuid;
      console.log('qewewqewe');
      
    }else{
      this.project_Id = this.archieveUuid;
      console.log('sdsadd');
      
    }
    var data = {
      files: [this.project_Id],
      entity_type: 6
    }
    console.log(data);
    this.modalService.openModal(data, 'dashboard');
    $('#deleteModalforArchieve_project').modal('hide');
    
    
    // console.log(data,"clicked");
    // this.ngxUiLoaderService.start();
    // this.api.bulkDeletePermenently(data).subscribe(data=>{
    // this.restoreLaterResponse = data;
    // console.log(this.restoreLaterResponse);
    
    // if(this.restoreLaterResponse.status == 200){
    //   this.ngxUiLoaderService.stop();
    //   this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
    //     duration:3000,
    //   })
    //   $('#deleteModalforArchieve_project').modal('hide');
    //   $('#deleteModalforArchieve_open').modal('hide');
    //   this.prjecTuuid = null;
    //   this.getDashboardDetails();
    // }else{
    //   this.ngxUiLoaderService.stop();
    //   this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
    //     duration:3000,
    //   })
      
    // }
     
    // },error=> {
    //   this.ngxUiLoaderService.stop();
    // });
    

  }

  openArchieveModal(){
    console.log('clicked',this.project_Id);
    console.log(this.archieveUuid);
    
    this.archieveUuid = this.project_Id;
    $('#deleteModalforArchieve_open').modal('hide');
    $('#timelineModal_open').modal('hide');
    $(".modal-backdrop").remove();
    setTimeout(() => {
      $('#deleteModalforArchieve_project').modal('show');
    }, 100);
    this.close()
  }

  updateTimeline(){
    $('#deleteModalforArchieve_open').modal('hide');
    $(".modal-backdrop").remove();
    this.router.navigate(['/project-settings', this.prjecTuuid],{ fragment: 'sectionId'})
  }

  openFolder(data,timeline,checkout_status,checkout_access) {
    console.log(data.uuid,data)
    if(timeline == 1){
      console.log(timeline);
      console.log(data.uuid);
      this.project_Id = data.uuid;
      this.folderdetailsdata = data;
      $('#timelineModal_open').modal('show');
    }else{
      console.log('vayo');
      if(checkout_status == 1 && checkout_access == 0){
        // alert('gsgdsagdvsg')
      }else{
        this.router.navigate(['/folders', data.uuid])
      }

    }
  }

  updatefolderTimeline(){
    $('#timelineModal_open').modal('hide');

    console.log(this.folderdetailsdata);
    console.log(this.folderdetailsdata.start_date);
    this.todayDate = this.folderdetailsdata.start_date;

    this.timelineForm.get('name').setValue(this.folderdetailsdata.name);
    this.timelineForm.get('folderuuid').setValue(this.folderdetailsdata.uuid);
    this.timelineForm.get('start_date').setValue(this.folderdetailsdata.start_date);
    this.timelineForm.get('end_date').setValue(this.folderdetailsdata.end_date);
    $('#folderEdit_modal').modal('show');
    
  }

  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      if(date.length == 1)
        return str;
      else
        return [date[3], mnths[date[1]], date[2]].join("-");
    }

  updateTimelinefolder(){
    let value= this.timelineForm.value;
    console.log(moment(this.timelineForm.value.end_date, 'MM/DD/YYYY',true).isValid())
    console.log(Date.parse(this.timelineForm.value.end_date))
    if (moment(this.timelineForm.value.end_date, 'MM/DD/YYYY',true).isValid()) {
      value['end_date'] = this.convertOwl(this.timelineForm.value.end_date);
    } 
    if (moment(this.timelineForm.value.start_date, 'MM/DD/YYYY',true).isValid()) {
      value['start_date'] = this.convertOwl(this.timelineForm.value.start_date);
    } 
    console.log( this.timelineForm.value);

    let data = this.timelineForm.value;
    this.ngxUiLoaderService.start();
    this.api.updateFolderTimeline(data).subscribe(data=>{
      console.log(data);
      this.timelineResponse = data;
      if(this.timelineResponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.timelineResponse.message, "Dismiss", {
          duration: 3000,
        })
        $('#folderEdit_modal').modal('hide');
        this.getDashboardDetails();
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.timelineResponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
    
  }

  closeTimelinemodal(){
    $('#folderEdit_modal').modal('hide');
  }

  checkoutModal(value=null){
    this.close();
    let data:any = {
      entity_type: 7,
      id: this.project_Id,
      name:this.shareName
    }
    if(value ){
      data.checkin = '2'
    }
    this.modalService.openModal(data,'dashboard'); 
  }

  openViewdetails(){
    this.close()
    this.shareuuid = this.project;
    console.log( this.shareuuid);
    this.sharedService.changeData(this.shareuuid);
  }

  downloadFile(){
    console.log('hello');
    let data ={
      file_id:this.project_Id
    }
    this.api.downloadFile(data).subscribe(data =>{
      console.log(data);
      this.downloadResponse = data;
      if(this.downloadResponse.status == 200){
        this.path_url =this.downloadResponse.path;
        this.path_name =this.downloadResponse.name;
        console.log(this.path_url);
        
        this.fileDownloadService.downloadFile(this.path_url, this.path_name);
      }
    })
    
  }

}
