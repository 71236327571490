<app-sidebar></app-sidebar>

<app-header></app-header>
<main>
    <div class="container-custom" id="reportsPage">
        <div class="columns">
            <div class="main">
                <div>
                    <form [formGroup]="selectForm">
                    <div class="heading_1">
                        <div class="d-flex align-items-center">
                            <h4>{{'Report Overview' | translate}}</h4>
                        </div>
                        <div class="d-flex align-items-center  dropDoewnporSelect">

                                <div class="dropDownOptions">
                                    <select formcontrolname="selectOption" (change)="onSelectionChange($event)"
                                        class="selectOptions">
                                        <option value=" " disabled selected>{{'Select options' | translate}}</option>
                                        <option value="1">{{'Number of documents uploaded' | translate}}</option>
                                        <option value="2">{{'Number of documents created in project' | translate}}</option>
                                        <option value="3">{{'Number of documents created  by user' | translate}}</option>
                                        <option value="4">{{'Number of documents created by priority' | translate}}</option>
                                        <option value="5">{{'Number of documents shared to user' | translate}}</option>
                                        <option value="6">{{'Number of documents shared to group' | translate}}</option>
                                        <option value="7">{{'Number of documents archived' | translate}}</option>
                                        <option value="8">{{'Number of documents deleted' | translate}}</option>
                                        <option value="9">{{'Number of documents downloaded' | translate}}</option>
                                        <option value="10">{{'Number of documents checkouted' | translate}}</option>
                                    </select>
                                </div>
                            
                        </div>
                    </div>
                    <div *ngIf="showDatePicker" class="date_div">

                        <div class="mt-3 options">
                            <div class="input_style align-items-center date-pickker">
                                <div class="priority" *ngIf="selectedOption == '4'">
                                    <label for="exampleInputEmail1">{{'Priority' | translate}}</label>
                                    <div class="select-createGroup">
                                        <mat-form-field class="custom-form-field2" appearance="none">
                                            <mat-select placeholder="{{'Select Priority' | translate}}"   formControlName="tag">
                                                <mat-option value="1"  >{{ 'High'| translate }}</mat-option>
                                                <mat-option value="2">{{'Medium' | translate}}</mat-option>
                                                <mat-option value="3">{{'Low' | translate}}</mat-option>
                                                <mat-option value="{{item.id}}"  *ngFor="let item of priority">{{item.name }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    
                                    </div>
                                </div>
                                <div class="datePicker">
                                    <div>
                                        <label for="">{{'Starts from' | translate}}</label>
                                        <mat-form-field class="example-full-width" appearance="none">
                                            <input matInput [matDatepicker]="dp10" [matDatepicker]="picker"
                                                class="dateSearch" placeholder="{{'Select date' | translate}}"
                                                readonly formControlName="start_date" (dateChange)="updateEndDate()">
                                            <mat-datepicker-toggle matIconSuffix [for]="dp10"></mat-datepicker-toggle>
                                            <mat-datepicker #dp10></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <img class="minus" src="assets/images/minus.png" alt="">
                                    </div>
                                    <div>
                                        <div>
                                            <label for="">{{'Ends on' | translate}}</label>
                                            <mat-form-field class="example-full-width" appearance="none">
                                                <input matInput [matDatepicker]="dp2" [matDatepicker]="picker"
                                                    class="dateSearch" placeholder="{{'Select date' | translate}}"
                                                    readonly formControlName="end_date" [min]="b.start_date.value">
                                                <mat-datepicker-toggle matIconSuffix [for]="dp2" ></mat-datepicker-toggle>
                                                <mat-datepicker #dp2></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn_div">
                                    <a type="submit" class="btn btn_primary bg_blue" (click)="onSubmit()">Submit</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    </form>
                </div>

                <div class="cutomised_reports">
                    <div class="head_section">
                        <h1>{{'Reports' | translate}}</h1>
                    </div>
                    <div class="card_container">
                        <div class="card bg-1stcard">
                            <div class="card-body card_contents">
                                <div class="d-flex"> 
                                    <div class="icon">
                                        <i class="material-icons">reorder</i>
                                    </div>
                                    <div class="content">
                                        <div class="text">{{'Number of documents uploaded' | translate}}</div>
                                        <div class="number" id="payment_tile">{{filesCount}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div class="card bg-2ndcard">
                          <div class="card-body card_contents">
                            <div class="d-flex"> 
                                <div class="icon">
                                    <i class="material-icons">reorder</i>
                                </div>
                                <div class="content">
                                    <div class="text">{{'Number of documents created in project' | translate}}</div>
                                    <div class="number" id="payment_tile">{{projectCount}}</div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="card bg-3rdcard">
                          <div class="card-body card_contents">
                            <div class="d-flex"> 
                                <div class="icon">
                                    <i class="material-icons">reorder</i>
                                </div>
                                <div class="content">
                                    <div class="text">{{'Number of documents created by user' | translate}}</div>
                                    <div class="number" id="payment_tile">{{userCount}}</div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div class="card bg-4thcard">
                            <div class="card-body card_contents">
                              <div class="d-flex"> 
                                  <div class="icon">
                                      <i class="material-icons">reorder</i>
                                  </div>
                                  <div class="content">
                                      <div class="text">{{'Number of documents created by priority' | translate}}</div>
                                      <div class="number" id="payment_tile">{{priorityCount}}</div>
                                  </div>
                                 
                              </div>
                            </div>
                        </div>
                        <div class="card bg-5thcard">
                            <div class="card-body card_contents">
                              <div class="d-flex"> 
                                  <div class="icon">
                                      <i class="material-icons">reorder</i>
                                  </div>
                                  <div class="content">
                                      <div class="text">{{'Number of documents shared to user' | translate}}</div>
                                      <div class="number" id="payment_tile">{{userSharedCount}}</div>
                                  </div>
                                 
                              </div>
                            </div>
                        </div>
                        <div class="card bg-6thcard">
                            <div class="card-body card_contents">
                              <div class="d-flex"> 
                                  <div class="icon">
                                      <i class="material-icons">reorder</i>
                                  </div>
                                  <div class="content">
                                      <div class="text">{{'Number of documents shared to group' | translate}}</div>
                                      <div class="number" id="payment_tile">{{GroupSharedCount}}</div>
                                  </div>
                                 
                              </div>
                            </div>
                        </div>
                        <div class="card bg-7thcard">
                            <div class="card-body card_contents">
                              <div class="d-flex"> 
                                  <div class="icon">
                                      <i class="material-icons">reorder</i>
                                  </div>
                                  <div class="content">
                                      <div class="text">{{'Number of documents archived' | translate}}</div>
                                      <div class="number" id="payment_tile">{{archivedCount}}</div>
                                  </div>
                                 
                              </div>
                            </div>
                        </div>
                        <div class="card bg-8thcard">
                            <div class="card-body card_contents">
                              <div class="d-flex"> 
                                  <div class="icon">
                                      <i class="material-icons">reorder</i>
                                  </div>
                                  <div class="content">
                                      <div class="text">{{'Number of documents deleted' | translate}}</div>
                                      <div class="number" id="payment_tile">{{deletedCount}}</div>
                                  </div>
                                 
                              </div>
                            </div>
                        </div>
                        <div class="card bg-9thcard">
                            <div class="card-body card_contents">
                              <div class="d-flex"> 
                                  <div class="icon">
                                      <i class="material-icons">reorder</i>
                                  </div>
                                  <div class="content">
                                      <div class="text">{{'Number of documents downloaded' | translate}}</div>
                                      <div class="number" id="payment_tile">{{downloadCount}}</div>
                                  </div>                           
                              </div>
                            </div>
                        </div>
                        <div class="card bg-10thcard">
                            <div class="card-body card_contents">
                              <div class="d-flex"> 
                                  <div class="icon">
                                      <i class="material-icons">reorder</i>
                                  </div>
                                  <div class="content">
                                      <div class="text">{{'Number of documents checkouted' | translate}}</div>
                                      <div class="number" id="payment_tile">{{checkoutCount}}</div>
                                  </div>                           
                              </div>
                            </div>
                        </div>
                    </div>

                    
                </div>

                <div class="graphs mt-5 mb-5">
                    <h5>{{'Storage Usage' | translate}}</h5>
                    <apx-chart [series]="chartOptions?.series" [chart]="chartOptions?.chart"
                        [dataLabels]="chartOptions?.dataLabels" [plotOptions]="chartOptions?.plotOptions"
                        [yaxis]="chartOptions?.yaxis" [xaxis]="chartOptions?.xaxis" [legend]="chartOptions?.legend"
                        [colors]="chartOptions?.colors" [grid]="chartOptions?.grid">
                    </apx-chart>
                    <div *ngIf="reportDataList && reportDataList.categories?.length == 0"
                        class="text-center userNotFound">
                        <img src="assets/images/noreport.jpg" class="img-fluid mb-2">
                        <p>{{'No reports Found' | translate}}</p>
                    </div>
                </div>
                <!-- 
                <div class="d-flex graph2">
                    <div class="graphs mt-5 mb-5">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex title-1 align-items-center">
                                <img src="assets/images/Icon-up.png" alt="">
                                <h5>{{'Upload' | translate}}</h5>
                            </div>
                            <div class="calender">
                                <img src="assets/images/calender_icon.png" alt="">
                                4th June - 10th June
                            </div>
                        </div>
                        <img src="assets/images/graph2.png" alt="" class="img-fluid w-100">
                    </div>
                    
                    <div class="graphs mt-5 mb-5">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex title-1 align-items-center">
                                <img src="assets/images/Icon-down.png" alt="">
                                <h5>{{'Downloads' | translate}}</h5>
                            </div>
                            <div class="calender">
                                <img src="assets/images/calender_icon.png" alt="">
                                4th June - 10th June
                            </div>
                        </div>
                        <img src="assets/images/graph3.png" alt="" class="img-fluid w-100">
                    </div>
                </div>
                -->


            </div>

            <!-- <aside class="sidebar-second " [ngClass]="{'width-side-second': showSideBar == true}"
            id="storageDetils">
            <div class="heading_1">
                <h4 class="text-center">{{'Cloud Storage' | translate}}</h4>
            </div>
            <img class="close_storage" *ngIf="showSideBar" (click)="getInfo('1')"
                src="assets/images/arrow_right.png" alt="">
            <img *ngIf="!showSideBar" class="close_storage2" (click)="getInfo('2')"
                src="assets/images/angle_left.png" alt="">
            <div class="d-flex align-items-center flex-column storage">
                <img class="img-fluid" src="assets/images/storage.png" alt="">
                <div class="text_sm mt-3">
                    13.6 GB of 50 GB {{'used' | translate}}
                </div>
            </div>

            <div class="storage p-0 mt-4" >
                <div class="card1" *ngFor="let details of dashboardRecentdetails">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <img class="mr-12" src="{{details.image}}" alt="">
                            <div>
                                <div class="subText">{{details.name | translate}}</div>
                                <div class="smText">{{details.no_of_file}} {{'Files' | translate}}</div>
                            </div>
                        </div>
                        <div class="text_sm">{{details.file_size}}</div>
                    </div>
                </div>
               
            </div>
        </aside> -->
        </div>







    </div>

    <div class="modal fade" id="folderCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="folderCreateModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal_header">
                    <h5>{{'Add New Project' | translate}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div id="projectNameAdd">
                    <div class="modal-body">
                        <div class="mt-5">
                            <div class="position-relative">
                                <input type="text" placeholder="Joining Date" name="" id="" value="Employee Datas">
                            </div>
                        </div>
                    </div>

                    <div class="btnGrp mt-4">
                        <button class="btn-primary cancel btn-style" data-bs-dismiss="modal"
                            aria-label="Close">{{'Cancel' | translate}}</button>
                        <button id="folderCreate" (click)="folderCreate()" class="btn-primary btn-style">{{'Create' |
                            translate}}</button>
                    </div>
                </div>

                <div id="folderName_And_Tagsection">
                    <div class="modal-body">
                        <div class="mt-2">
                            <label for="">{{'Folder Name' | translate}}</label>
                            <div class="position-relative">
                                <input type="text" placeholder="Joining Date" name="" id=""
                                    value="Employee Offer Letter">
                            </div>
                        </div>

                        <div class="mt-2">
                            <label for="">{{'Tags' | translate}}</label>
                            <div class="position-relative">
                                <input type="text" placeholder="Tags" name="" id="">
                            </div>
                        </div>
                    </div>

                    <div class="btnGrp mt-4">
                        <button class="btn-primary cancel btn-style" data-bs-dismiss="modal"
                            aria-label="Close">{{'Cancel' | translate}}</button>
                        <button id="folderCreate" class="btn-primary btn-style" data-bs-dismiss="modal"
                            aria-label="Close">{{'Create' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</main>