import { Component,OnDestroy, OnInit,Output, EventEmitter ,ElementRef, ViewChild, Renderer2,AfterViewInit,ViewContainerRef,TemplateRef,HostListener} from '@angular/core';
import { ApiService } from '../../_services/api.services';
declare var $ : any;
import { Overlay, OverlayRef, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatSnackBar } from '@angular/material';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormBuilder, FormGroup, FormArray,Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { TemplatePortal, ComponentPortal } from '@angular/cdk/portal';
import { fromEvent, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../_services/modal.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { log } from 'console';
import {FileDownloadService} from '../../_services/download.service';

@Component({
  selector: 'app-searchedfiles',
  templateUrl: './searchedfiles.component.html',
  styleUrls: ['./searchedfiles.component.scss']
})
export class SearchedfilesComponent implements OnInit, OnDestroy{
  @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef;
  @ViewChild('inputSearch2', { static: false }) inputSearch2: ElementRef;
  @ViewChild('qryButton3', { static: false }) private qryButton3: ElementRef<HTMLButtonElement>;
  @ViewChild('overlayMenuList', { static: false }) overlayMenuList: TemplateRef<HTMLElement>;
  overlayRef3: OverlayRef;
  dataSubscription: any;
  serchResponse: any;
  serchedDatas: any[] = [];
  selectedProject= null;
  projectList : any;
  projectData: any;
  selectedTemplate = null;
  selectedProjectType = null;
  templateList : any;
  allList : any;
  templateData: any;
  selectedTag =  null;
  tagList : any;
  tagData: any;
  filterResponse: any;
  filteredData: any[] = [];
  serchedDataFolder: any[] = [];
  serchedDataProject: any[] = [];
  searchForm: FormGroup;
  limit = 3;
  sub: Subscription;
  value: any;
  profileForm: FormGroup;
  submitted = false;
  rangevalue = 0;
  templateresponse: any;
  templateDetails: any;
  responseProjectTypes: any;
  ProjectTypeslist: any;
  responseFolderList: any;
  allFolder: any;
  startDate: any;
  endDate: any;
  maxDate: Date = new Date();
  path_url: SafeUrl;
  todayDate:Date = new Date();
  folder_uuid: any;
  filename: any;
  name: any;
  type: any;
  file_Id: any;
  folder: any;
  signature: any;
  currentValue: any;
  overlayRef: OverlayRef | null;
  delete = false;
  @ViewChild('userMenu', { static: false }) userMenu;
  @ViewChild('projectuserMenu', {static: false}) projectuserMenu;
  projectGetlinkresponse: any;
  getlink: any;
  bulkarchieFilesID: any[] = [];
  restoreLaterResponse: any;
  uuidDetails: any;
  id: any;
  viewDetailsData: any;
  viewDetailsList: any;
  metadataDetails: any;
  metadataDetailsDate: any;
  metadatName: any;
  viewDetailsList2: any;
  activityDetails: any;
  showSideBar = false;
  storageType: any;
  infoSection = true;
  activitySection = false;
  ee1Subscription: Subscription;
  prjecTuuid: any;
  archieveUuid: any;
  project_Id: any;
  projectTYpee: boolean;
  metadataForm: FormGroup;
  metadataId: any;
  UpdateMetadataResponse: any;
  isDisabled: boolean = true;
  isDatePickerDisabled: boolean = true;
  isInputDisabled: boolean = true;
  isReadOnly: boolean = true;
  isButtonHidden: boolean = false;
  isButtonHiddenCancel : boolean = true;
  isButtonHiddenSubmitt : boolean = true;
  project: any;
  projectResponse: any;
  deleteResponse: any;
  NometadataAdded: any;
  NometadataforFolder: any;
  keyHide: boolean;
  filterProjects: any[] = [];
  filterFolder: any[] = [];
  linkuuid: any;
  priorityResponse: any;
  priority: any;
  showCheckBox: boolean;
  selectedFilesId: any;
  projectFilesID: any[] = [];
  selectedItems: number[] = [];
  filterSection: boolean;
  searchSection: boolean;
  folderdetailsdata: any;
  timelineForm: FormGroup;
  timelineResponse: any;
  searchValue: any;
  hideButtonPath: any;
  isSelected: boolean;
  selected:boolean;
  getLinkForm: FormGroup;
  shareName: any;
  filemove: boolean = true;
  uuID: any;
  get_fileId: any;
  get_ProjectId: any;
  showMessage: boolean;
  listArrayPreview: any;
  downloadResponse: any;
  path_name: any;
  path_urlDownload: any;
  
  

  constructor(private api: ApiService,
              private overlay: Overlay,
              public snackBar : MatSnackBar,
              private ngxUiLoaderService: NgxUiLoaderService,
              private renderer: Renderer2,
              private formBuilder: FormBuilder,
              private location: Location,
              private viewContainerRef: ViewContainerRef,
              public router: Router,
              private modalService: ModalService,
              private route: ActivatedRoute,
              private datePipe: DatePipe,
              private sanitizer: DomSanitizer,
              private fileDownloadService : FileDownloadService) { 
                this.getProjectFilter();
                this.getTemplateFilter();
                this.getTagFilter();
                this.getProjectType();
                this.getPriority();
                
                
              }
  

  ngOnInit() {
    $("#search_button").hide();
    $("#close-search").hide();
    $("#inputSearch").keyup(function () {
      if ($(this).val()) {
        $("#search_button").show();
        $("#close-search").show();
      }
      else {
        $("#search_button").hide();
        $("#close-search").hide();
      }
    });
    $("#close-search").click(function () {
      $("#inputSearch").val('');
      $(this).hide();
      $("#search_button").hide();
    });

    $("#search_button2").hide();
    $("#close-search2").hide();
    $("#inputSearch2").keyup(function () {
      if ($(this).val()) {
        $("#search_button2").show();
        $("#close-search2").show();
      }
      else {
        $("#search_button2").hide();
      }
    });
    $("#close-search").click(function () {
      $("#inputSearch").val('');
      $(this).hide();
      $("#search_button").hide();
    });

    $("#close-search2").click(function () {
      $("#inputSearch2").val('');
      $(this).hide();
      $("#search_button2").hide();
    });

    setTimeout(()=> {
      this.setFocusOnSearchInput();
      this.setFocusOnSearchInput1();
    },100)

    this.metadataForm = this.formBuilder.group({
      metadatadetails: this.formBuilder.array([''])
    });


    this.searchForm = this.formBuilder.group({
      searchInput: [''] 
    });

    this.profileForm = this.formBuilder.group({
      project_id: [''], 
      template_id: [''], 
      tag_id: [''],
      timeline: [''],
      project_type_id:[''],
      // project_start_date:[''],
      // project_end_date:[''],
      metadatadetails: this.formBuilder.array([''])
    });

    this.timelineForm = this.formBuilder.group({
      name:[''],
      folderuuid:[''],
      start_date:[''],
      end_date:['']

    })
    

    this.ee1Subscription = this.modalService.showSearch.subscribe((data: any) => {
      console.log("Midilaj",data)
      this.serchedDatas = [];
      this.serchedDataFolder = [];
      this.serchedDataProject = [];
      console.log(data)
      this.startDate = new Date();
      console.log("this.startDate", this.startDate)
      this.startDate = moment(this.startDate).utc().tz('Africa/Libreville').format('YYYY-MM-DD');
      console.log("this.startDate", this.startDate)
      let currentDate = new Date();
      this.endDate =  new Date(currentDate.getTime());
      this.endDate = moment(this.endDate).utc().tz('Africa/Libreville').format('YYYY-MM-DD');
      console.log("this.endDate", this.endDate)
      console.log('4333333333333333333333',data)
      if (data == 'search') {
        this.serchedDatas = [];
        this.filteredData = [];
        this.projectFilesID =[];
        this.showCheckBox = false;
        this.serchedDataFolder = [];
        this.serchedDataProject = [];
        this.close();
        this.showSideBar = false;
        this.searchValue =null;
        this.searchForm.reset()
        this.ngOnInit();
      } else if (data == 'filter' ){
        this.projectFilesID =[];
        this.showCheckBox = false;
        this.showSideBar = false;
        this.searchValue =null;
        this.resetFiletrs();
        this.overlayRef3.detach();
        console.log("Sdsadsada")
        this.displayMenu3();
        if (document.getElementsByClassName("cdk-overlay-connected-position-bounding-box").length > 1) {
          document.getElementsByClassName("cdk-overlay-connected-position-bounding-box")[0].remove();
        }
        // this.ngOnInit();
        return
      } else if (data == 'modalsearch') {
        console.log('qqqqqqqqqqqq');
        console.log(this.searchForm.get('searchInput').value);
        if(this.searchForm.get('searchInput').value){
          this.submit_handler('inputSearch');
        }else{
          this.applyFilters();
        }

      }
      // setTimeout(() => {
      //   console.log("sdsadsadsadsadsadsadsadsadsadsadsadsad")
      //   this.ee1Subscription.unsubscribe();
      // }, 500)
    });
    this.currentValue = JSON.parse(localStorage.getItem('currentUserDetails'));
    this.storageType = this.currentValue.user_details;

    this.getLinkForm = this.formBuilder.group({
      comment : [''],
      uuid:['']
    });
    this.prjecTuuid = [];
    this.archieveUuid = [];

  }
  // ngOnDestroy(): void {
  //   // Cleanup logic here
  //   console.log('ngOnDestroy called');
  // }
  
  ngAfterViewInit(): void {
    this.queryCtrl3OverlayConfig();
  }

  get c(){
    return this.timelineForm.controls;
  }

  get b() {
    return this.profileForm.controls;
  }
  get z() { return this.metadataForm.controls.metadatadetails as FormArray; }
  
  setFocusOnSearchInput() {
    this.inputSearch.nativeElement.focus();
  }
  setFocusOnSearchInput3() {
    this.qryButton3.nativeElement.focus();
  }

  valueChanged(e) {
    console.log(this.rangevalue);
    
    this.rangevalue = e.target.value;
  }

  ngOnDestroy() {
    this.close(); 
    console.log("this.ee1Subscription", this.ee1Subscription)
    // Unsubscribe from the event emitter to prevent memory leaks
    if (this.ee1Subscription) {
      console.log("asdgasdhsadgdhfsafdhgsafdghsafdghsafdghsa")
      this.ee1Subscription.unsubscribe();
    }
  }

  
  closeFiltermodal(){
    this.selectedProject = null;
    this.selectedTemplate = null;
    this.selectedTag = null;
    this.selectedProjectType =null;
  }
  
  updatevalue(metadataDetails) {
    console.log(metadataDetails)
    metadataDetails.forEach(obj => {
      Object.keys(obj).forEach(key => {
          if (obj[key] === undefined) {
              // Set a default value here, for example:
              obj[key] = '';
          }
      });
    });
    console.log(metadataDetails)
    return metadataDetails;
  }

  checkAndSetFields(fields) {
    for (let field of fields) {
        let hasNull = false;

        // Check if any value in the current field is empty, undefined, or null
        for (let key in field) {
          console.log(field[key])
          console.log(moment.isMoment(field[key]))
            if (field[key] === '' || field[key] === undefined || field[key] === null) {
                hasNull = true;
                break;
            }
            if (moment.isMoment(field[key])) {
              console.log(field[field.field_id])
              console.log(field[key])
              field[field.field_id] = this.datePipe.transform(field[field.field_id], 'yyyy-MM-dd')
            }
        }

        // If any value is null, set all values in the current field to null
        if (hasNull) {
            for (let key in field) {
                field[key] = null;
            }
        }
    }

    return fields;
  }

   applyFilters() {
    
    console.log(this.profileForm.value);
    this.listArrayPreview = [];
    const formValues = this.profileForm.value;
    console.log(formValues);
    console.log(formValues.timeline);
    
    // const filledControls = Object.values(formValues).filter(value => value !== null);
    // console.log(filledControls );
    
    if (formValues.timeline == '0' && formValues.project_id == null &&
      formValues.project_type_id== null && formValues.tag_id== null && formValues.template_id== null) {
      console.log('dd');
      this.showMessage = true;
      return true;  
    }
    console.log('dfssssssssssss');
    
    this.showCheckBox = false;
    this.projectFilesID = [];
    this.openSideMenu1('close')
    this.searchForm.reset()
    this.searchValue = null;
    // this.searchForm.reset()
    this.submitted = true;
    this.filterSection = false;
    this.searchSection = false;
    this.serchedDatas = [];
    this.serchedDataProject = [];
    this.serchedDataFolder = [];
    this.filteredData = [];
    console.log(this.profileForm.value)
    this.profileForm.controls.metadatadetails.updateValueAndValidity();
    const data = this.profileForm.value;
    console.log(data)
    this.checkAndSetFields(data.metadatadetails);
    console.log(data);
    // data['project_start_date'] = this.datePipe.transform(this.profileForm.value.project_start_date, 'dd-MM-yyyy')
    // data['project_end_date'] = this.datePipe.transform(this.profileForm.value.project_end_date, 'dd-MM-yyyy');
    console.log(data.metadatadetails)
    if (data.metadatadetails[0] != null) {
      data['metadatadetails'] = this.updatevalue(data.metadatadetails)
    }
    console.log(data);

    console.log('Selected Project:', this.selectedProject);
    console.log('Selected Template:', this.selectedTemplate);
    console.log('Selected Tag:', this.selectedTag);
    console.log(this.selectedProjectType);
    console.log(data);
    console.log(this.anyControlHasValue())
    console.log(this.profileForm.value)
    if (!this.anyControlHasValue()) {
      return
    }
    this.close();
    this.ngxUiLoaderService.start();
    this.api.applyFilterType(data).subscribe(data=>{
      console.log(data);
      this.filterResponse = data;
      if(this.filterResponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.filterSection = true;
        this.hideButtonPath = this.filterResponse.path;
        this.path_url = this.sanitizer.bypassSecurityTrustUrl(this.filterResponse.path);
        console.log(this.path_url);
        
        this.filteredData = this.filterResponse.result;
        console.log(this.filteredData);
        this.filterProjects = this.filterResponse.project;
        console.log(this.filterProjects);
        this.filterFolder =  this.filterResponse.folder;
        console.log(this.filterFolder);
        this.showMessage = false;
        this.rangevalue = 0;
       
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.filterResponse.message, "Dismiss", {
          duration:3000,
        })
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    });

  }

  logPathUrl() {
    console.log('path_url:', this.path_url);
    console.log('hideButtonPath:', this.hideButtonPath);
  }

  anyControlHasValue(): boolean {
    return Object.values(this.profileForm.value).some(value => value !== null && value !== '' && value[0] !== '');
  }

 
  onDateChange(): void {
    console.log('ddddddddddddd');
    
    const selectedDate = this.profileForm .get('dateof_birth').value;
    console.log(selectedDate,'aaaaaaaaaaa');
    
    const formattedDate = this.formatDate(selectedDate);
    console.log(formattedDate);
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  submit_handler(key){
    this.serchedDatas = [];
    this.profileForm.reset();
    this.showCheckBox = false;
    this.projectFilesID = [];
    this.filterSection = false;
    this.searchSection = false;
    this.serchedDataProject = [];
    this.serchedDataFolder = [];
    this.filteredData = [];
    this.listArrayPreview = [];
    this.openSideMenu1('close')
    this.close();
    this.searchValue = this.searchForm.get('searchInput').value;
    let myInput:any = document.getElementById(key);
    myInput.value = this.searchValue;
    console.log(myInput.value);
    
    if (myInput.value != "") {
      let data = {
        file_name : myInput.value
      }
      console.log(data);
      this.ngxUiLoaderService.start();
      this.api.serchFilesorfolder(data).subscribe(data=>{
        console.log(data);
        this.serchResponse = data;
        console.log(this.serchResponse);
        if(this.serchResponse.status == 200){
          console.log(this.serchResponse);
          this.searchSection = true;
          this.serchedDatas =  this.serchResponse.file;
          this.serchedDataFolder = this.serchResponse.folder;
          console.log(this.serchedDataFolder);
          
          this.serchedDataProject = this.serchResponse.project;
          console.log(this.serchedDataProject);
          this.ngxUiLoaderService.stop();          
        } 
      }, error => {
        this.ngxUiLoaderService.stop();
      });
    }
  }
  getProjectFilter(){
    this.api.getProject().subscribe(data=>{
      this.projectData = data;
       if(this.projectData.status == 200){
        this.projectList = this.projectData.results;
        console.log(this.projectList,'qwqwqwqwqwq');
        
      }else{
        // this.snackBar.open(this.projectData.message, "Dismiss", {
        //   duration:3000,
        // })
      }
    }, error => {

    });
  }
  getTemplateFilter(){
    this.api.getTemplate().subscribe(data=>{
      console.log(data);
      this.templateData = data;
      if(this.templateData.status== 200 ){
        this.templateList = this.templateData.results;
        this.allList = this.templateData.results;
        console.log(this.templateList);
        
      }else{
        // this.snackBar.open(this.templateData.message, "Dismiss", {
        //   duration:3000,
        // })
      }
    }, error => {

    });
  }
  close() {
    console.log('aaa')
    this.overlayRef3.detach();
    this.closeFiltermodal();
    console.log(this.profileForm.value)
  }
  closeProject() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
  getTagFilter(){
    this.api.getTag().subscribe(data=>{
      this.tagData = data;
       if(this.tagData.status ==  200){
        this.tagList = this.tagData.results;
        console.log(this.tagList,'qwqwqwqw'); 
      }else{
        // this.snackBar.open(this.tagData.message, "Dismiss", {
        //   duration:3000,
        // })
      }

    }, error => {
      
    });
  }
  queryCtrl3OverlayConfig() {
    console.log(this.qryButton3)
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.qryButton3.nativeElement)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]);

    this.overlayRef3 = this.overlay.create({
      positionStrategy: strategy,
      panelClass: ['filter-panel-class']
    });
  }

  displayMenu3() {
    this.templateDetails = '';
    this.resetFiletrs();
    console.log(this.overlayRef3);
    if (this.overlayRef3 && this.overlayRef3.hasAttached()) {
      this.overlayRef3.detach();
      console.log('LLLLL')
    } else {
      console.log('HHHH', this.overlayRef3)
      this.overlayRef3.attach(
        new TemplatePortal(this.overlayMenuList, this.viewContainerRef)
        
        
      );
     
    }
    this.sub = fromEvent<MouseEvent>(document, 'mousedown')
      .pipe(
        filter(mouseEvent => {
          const clickTarget = mouseEvent.target as HTMLElement;
          return !!this.overlayRef3 && !this.overlayRef3.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe((e) => {
        console.log(e)
        const clickTarget = e.target as HTMLElement;
        // if (!clickTarget.classList.contains('mat-calendar-body-cell-content'))
        // this.close()
      });
      this.getProjectFilter();
      this.getTemplateFilter();
      this.getTagFilter();
      this.getProjectType();
      this.getPriority();
  }

  clearSearchInput() {
    this.searchForm.get('searchInput').setValue('');
  }
  setFocusOnSearchInput1() {
    this.inputSearch2.nativeElement.focus();
  }

  goBack(){
    this.location.back();
  }

  onSelectionChange(event) {
    console.log(event);
    
    console.log(this.b.template_id.value);
    
    this.gettemplateData(this.b.template_id.value);
  }

  gettemplateData(data) {
    this.ngxUiLoaderService.start();
    this.api.gettemplateData(data).subscribe(data=>{
      console.log(data);
      this.templateresponse = data;
      if(this.templateresponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.templateDetails = this.templateresponse.template_details.keyword;
        console.log( this.templateDetails)
        for (let key in this.templateDetails) {
          var metadata = this.profileForm.controls.metadatadetails as FormArray;
          console.log("this.templateDetails[key].keyword",this.templateDetails[key].keyword)
          console.log("this.templateDetails[key].keyword",metadata.controls[key])

          metadata.controls[key] = this.formBuilder.group({
            [this.templateDetails[key].id]: '',
            field_id: '',
          }) 
          metadata.controls[key].controls.field_id.setValue(this.templateDetails[key].id) 
          console.log(this.profileForm.controls.metadatadetails)
        }
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.templateresponse.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }


  resetFiletrs() {
    this.submitted = false;
    this.templateDetails = '';
    this.profileForm.reset();
    this.profileForm .get('timeline').setValue('0');
  }


  getImagePreview(image,val=null) {
    if (val == 1) {
      console.log('filterDAta');
      this.listArrayPreview = this.filteredData;
      console.log(this.listArrayPreview);
      
    }else{
      console.log('searchDAta');
      this.listArrayPreview = this.serchedDatas;
      console.log(this.listArrayPreview);
      
    }
    this.close();

    if (!this.showCheckBox) {
      var data = {
        entity_type: 4,
        data: image,
        list: this.listArrayPreview
      }
      this.modalService.openModal(data);
    }
    
  }

  getProjectType() {
    this.api.getProjectType().subscribe(data=>{
      this.responseProjectTypes = data;
      if (this.responseProjectTypes.status == 200) {
        this.ProjectTypeslist = this.responseProjectTypes.result;
        console.log(this.ProjectTypeslist);
        
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  downloadPath(){
    //window.open(this.path_url, '_blank');
    console.log('hhhhhhhhhhhhhhhhhhhhhhhhhh');
    
  }

  open(event: MouseEvent, user) {
    event.preventDefault();
    event.stopPropagation();
    this.file_Id = null;

    // if(user.project_time_exceeded == 1 &&  (this.showCheckBox)){
    //   return
    // }

    console.log(user, 'AAAAA');
    this.folder_uuid = user.uuid;
    this.filename = user.file_name;
    this.name = user.folder_name;
    this.type = user.type;
    this.file_Id = user.uuid;
    this.get_fileId = user.uuid;
    console.log(this.get_fileId);
    
    this.shareName = user.name;
    console.log(user.name);
    this.folder = user;
    console.log(this.folder,);
    this.bulkarchieFilesID.push(this.file_Id)
    
    if( user.checkout_status == 1 && user.checkout_access == 0){
      return
    }

    if (!this.showCheckBox){
      console.log('hahaha');
      
      this.file_Id = [user.uuid];
    }else{
      console.log('buhahah');
      
      this.file_Id = this.projectFilesID;
    }
    if (this.folder.pdf_type) {
      this.signature = true;
    } else {
      this.signature = false;
    }
    if (this.folder.project_type == 0) {
      this.projectTYpee = true
    }else{
      this.projectTYpee = false;
    }

    if (this.folder.file_name) {
      if (this.currentValue.permissions.deleteFile) {
        this.delete = true;
      } else {
        this.delete = false;
      }
    } else {
      if (this.currentValue.permissions.deleteFolder) { 
        this.delete = true;
      } else {
        this.delete = false;
      }
    }
    console.log(this.file_Id);
    this.close1();
    this.close();
    this.closeProject()
    const isNearBottom = window.innerHeight - (event.clientY + window.scrollY) < 240;
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: event.x, y: event.y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: isNearBottom ? 'bottom' : 'top',
        }
      ]);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });
    this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
      $implicit: user
    }));
    this.sub = fromEvent<MouseEvent>(document, 'mousedown')
      .pipe(
        filter(mouseEvent => {
          const clickTarget = mouseEvent.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => this.close1());
  }

  deleteModal(){
    console.log('clicked');
    $('#deleteModal3').modal('show');
    this.close1();
  }
  



  opencontext(event: MouseEvent, user) {
    this.shareName = null;
    // Prevent the default context menu to avoid interference with your custom context menu
    event.preventDefault();
    event.stopPropagation();
    this.file_Id = null;
    console.log(user, 'AAAAA');
    this.project_Id = user.uuid;
    this.folder_uuid = user.uuid;
    this.project = user;
    this.file_Id = user.uuid;
    this.shareName = user.name;
    this.get_ProjectId = user.uuid;
    if( user.checkout_status == 1 && user.checkout_access == 0){
      return
    }

    
    if (!this.showCheckBox){
      console.log('hahaha');
      
      this.file_Id = [user.uuid];
    }else{
      console.log('buhahah');
      
      this.file_Id = this.projectFilesID;
    }
    
    console.log(this.project);
    
    this.close1();
    this.closeProject();

    //extra added for bottom breaking
    const isNearBottom = window.innerHeight - (event.clientY + window.scrollY) < 240;

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x: event.x, y: event.y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: isNearBottom ? 'bottom' :'top',
        }
      ]);
  
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });
  
    this.overlayRef.attach(new TemplatePortal(this.projectuserMenu, this.viewContainerRef, {
      $implicit: user
    }));
  
    this.sub = fromEvent<MouseEvent>(document, 'mousedown')
      .pipe(
        filter(mouseEvent => {
          const clickTarget = mouseEvent.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => this.closeProject());
  }



  toShare(uuid=null,event: MouseEvent=null,name=null) {
    console.log(this.folder_uuid);
    if(event){
      event.stopPropagation();
    }
    this.close1();
    var data = {
      entity_type: 2,
      id: this.file_Id,
      name: this.shareName
    }
    if (uuid) {
      data['id'] = uuid;
      data['name'] = name;
    }
    this.modalService.openModal(data ,'modalsearch');
  }
  
  // toSharee(uuid=null,event: MouseEvent) {
  //   console.log(this.folder_uuid);
    
  //   this.close1();
  //   var data = {
  //     entity_type: 2,
  //     id: this.folder_uuid
  //   }
  //   if (uuid) {
  //     data['id'] = uuid
  //   }
  //   this.modalService.openModal(data ,'project');
  // }

  close1() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
  deleteProject(){
    let FileId = {files: this.file_Id};
    console.log(FileId);
    
    this.ngxUiLoaderService.start();
    this.api.bulkDeletefiles(FileId).subscribe(data => {
      console.log(data, '111');

      this.deleteResponse = data;
      console.log(this.deleteResponse, "2323232323232");
      if (this.deleteResponse.status == 200) {
        this.ngxUiLoaderService.stop();
        $('#deleteModal3').modal('hide')
        $(".modal-backdrop").remove();
        this.snackBar.open(this.deleteResponse.message, "Dismiss", {
          duration: 3000,
        })

        if(this.searchValue !== null){
          console.log("11111111111111111",this.searchValue);
          
          this.submit_handler('inputSearch');
        }else{
          console.log("22222222222");
          this.applyFilters();
        }
       

      } else {
        this.snackBar.open(this.deleteResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.ngxUiLoaderService.stop();
      }

    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  getlinkModal(){
    this.close1();
    this.closeProject();
    $('#getLinkCreateModal').modal('show');
    
  }
  
  closseGetlink(){
    // this.getLinkSubmitted = false;
    $('#getLinkCreateModal').modal('hide');
    this.getLinkForm.reset();
  }




  

  getLink(){
    this.close1();
    this.linkuuid = this.get_fileId;
    if(!this.linkuuid){
      console.log('hoi');
      this.linkuuid = this.get_ProjectId;
    }
    else{
      console.log('holoii');
    }

    let data = this.getLinkForm.value;
    data['uuid'] = this.linkuuid;
     
    console.log(data);
    
    this.ngxUiLoaderService.start();
    this.api.getProjectLink(data).subscribe(data =>{
      
      console.log(data);
      this.projectGetlinkresponse = data;
      if(this.projectGetlinkresponse.status == 200){
        this.ngxUiLoaderService.stop();

        console.log(this.projectGetlinkresponse);
        
        this.getlink = this.projectGetlinkresponse.result.link;

        // console.log(this.getlink);
        // var tempInput = $("<input>");
        // tempInput.val(this.getlink);
        // $("body").append(tempInput);
        // tempInput.select();

        // document.execCommand("copy");

        if (navigator.clipboard) {
          navigator.clipboard.writeText(this.getlink).then(function() {
              console.log('Link copied to clipboard successfully!');
          })
        }
         else {
        console.error('Clipboard not copied...!');
        }
        // tempInput.remove();
        
        this.snackBar.open(this.projectGetlinkresponse.message, "Dismiss", {
          duration:3000,
        })
        
        $('#getLinkCreateModal').modal('hide');
        this.getLinkForm.reset();
        this.file_Id =  null;
        this.folder_uuid = null;
        
      }
      else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.projectGetlinkresponse.message, "Dismiss", {
          duration:3000,
        })
      }
      
    }, error => {
          this.ngxUiLoaderService.stop();
        });

  }

  moveProjectsFileorFolder() {
    this.close1();
    var data = {
      entity_type: 1,
      id: this.file_Id,
      name: this.shareName
    }
    this.modalService.openModal(data, 'modalsearch1');
  }

  restoreLater(){
    this.close1();
    console.log(this.bulkarchieFilesID);
    
    // if(this.prjecTuuid){
    //   this.file_Id = [this.file_Id];
    //   console.log('qewewqewe');
      
    // }else{
    //   this.file_Id = [this.file_Id];
    //   console.log('sdsadd');
      
    // }
    var data = {
      files: this.file_Id,
      entity_type: 6
    }
    console.log(data);

    this.modalService.openModal(data, 'searchfile');
    $('#deleteModalforArchieve_project').modal('hide');
    // console.log(data,"clicked");
    // this.ngxUiLoaderService.start();
    // this.api.bulkDeletePermenently(data).subscribe(data=>{
    // this.restoreLaterResponse = data;
    // console.log(this.restoreLaterResponse);
    
    // if(this.restoreLaterResponse.status == 200){
    //   this.ngxUiLoaderService.stop();
    //   this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
    //     duration:3000,
    //   })
    //   $('#deleteModalforArchieve_project').modal('hide');
    //   $('#deleteModalforArchieve_open').modal('hide');
    //   this.prjecTuuid = null;
    //   this.applyFilters();
    //   this.submit_handler('inputSearch');
    // } else{ 
    //   this.ngxUiLoaderService.stop();
    //   this.snackBar.open(this.restoreLaterResponse.message, "Dismiss", {
    //     duration:3000,
    //   })
    // }
    // },error=> {
    //   this.ngxUiLoaderService.stop();
    // });
  }

  rename() {
    this.close1();
    var data = {
      entity_type: 3,
      id: this.folder_uuid,
      data:this.folder
    }
    console.log(data);
    this.modalService.openModal(data, 'modalsearch');
  }

  openSideMenu(key, user = null) {
    
    this.showSideBar = true;
    this.close1();
    console.log(user, 'dd')
    if (user) {
      this.uuidDetails = user.uuid
      this.id = user.id
      console.log(this.id);
    }
    console.log(this.id);
    let data = this.file_Id;
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.getViewDetails(data).subscribe(data => {
      this.ngxUiLoaderService.stop();
      console.log(data,'joooooooooooooooooooooooo');
      this.viewDetailsData = data;
      console.log(this.viewDetailsData);

      if (this.viewDetailsData.status == 200) {
        console.log(this.viewDetailsData);
        this.viewDetailsList = this.viewDetailsData.file_Preview_result.file_list;
        console.log(this.viewDetailsList);

        this.metadataDetails = this.viewDetailsData.file_Preview_result.metadata_details
        console.log(this.metadataDetails);

        this.NometadataAdded = this.viewDetailsData.file_Preview_result.key;
        if(this.NometadataAdded == 1){
          console.log('555');
          
          this.keyHide = true;
        }
        this.NometadataforFolder = this.viewDetailsData.file_Preview_result.folder_key;
        


        if(this.metadataDetails){
          this.metadataDetailsDate = this.metadataDetails.keyword;
          this.metadatName = this.metadataDetails.template_details;
          this.metadataId = this.metadataDetails.template_uuid;
          console.log(this.metadataDetailsDate);
        }else{
          this.metadatName = '';
        }
        console.log(this.metadataDetailsDate,this.metadataId);
        if(this.metadataId &&  this.metadataDetails  && this.metadataDetails.keyword){
          console.log(this.metadataDetailsDate,this.metadataId);
          this.updateMetadetailsPreview(this.metadataDetailsDate)
        }
        this.getTemplateFilter();
        console.log(this.metadatName);
        this.viewDetailsList2 = this.viewDetailsData.file_Preview_result.shared_user;
        console.log(this.viewDetailsList2);
        this.activityDetails = this.viewDetailsData.file_Preview_result.activiy;
        console.log(this.activityDetails);
        if(this.NometadataAdded == 1){
          console.log("this.z", this.z.controls)
          this.z.clear();
        }
      } else {
        this.snackBar.open(this.viewDetailsData.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }


  show(key) {
    if (key == 'info') {
      this.infoSection = true;
      this.activitySection = false;
    } else {
      this.infoSection = false;
      this.activitySection = true;
    }
  }

  openSideMenu1(val) {
    this.showSideBar = false;
  }

  getSignatureRequest() {
    this.close1();
    var data = {
      entity_type: 5,
      id: this.folder_uuid,
      data:this.folder
    }
    console.log(data);
    this.modalService.openModal(data, 'signature');
  }

  openProject(uuid,timeline,checkout_status,checkout_access) {
    if(timeline == 1){
      console.log(timeline);
      console.log(uuid);
      this.file_Id = [uuid];
      this.prjecTuuid = uuid;
      $('#deleteModalforArchieve_open').modal('show');
    }else{
      if (!this.showCheckBox){
        if(checkout_status == 1 && checkout_access == 0){

        }else{
          this.router.navigate(['/folders', uuid]);
        }
        
      }
      
    }
  }

  updateTimeline(){
    $('#deleteModalforArchieve_open').modal('hide');
    $(".modal-backdrop").remove();
    this.router.navigate(['/project-settings', this.prjecTuuid],{ fragment: 'sectionId'})
  }

  openArchieveModal(){
    this.close1();
    console.log('clicked',this.file_Id);
    if(this.projectFilesID.length > 0){
      this.archieveUuid = this.projectFilesID;
    }else{
      this.archieveUuid = this.file_Id;
    }


    console.log(this.archieveUuid);

    
    $('#timelineModal_open').modal('hide');
    $('#deleteModalforArchieve_open').modal('hide');
    $(".modal-backdrop").remove();
    setTimeout(() => {
      $('#deleteModalforArchieve_project').modal('show');
    }, 100);
    

  }

  updateMetadetailsPreview(val, preview=null) {
    console.log("fds", val)
    this.metadataForm.reset();
    console.log(this.metadataForm);
    
    var metadatas = this.metadataForm.controls.metadatadetails as FormArray;
    console.log(metadatas);
    
    metadatas.clear();
    console.log(metadatas);
    for (let key in val) {
      console.log(metadatas)
      console.log(metadatas.controls)
      console.log(metadatas.controls)
      console.log(key);
      
      metadatas.controls[key] = this.formBuilder.group({
        [val[key].id]: ['',[Validators.required]],
        field_type: ['',[Validators.required]],
        id: ['',[Validators.required]],
        keyword: ['',[Validators.required]],
        keyword_label: ['',[Validators.required]]
      })
      console.log(val[key].id,'qqqqqqqqqqqqqqqqqqqqqqqqqqqq');
      
      metadatas.controls[key].get('field_type').setValue(val[key].field_type) 
      metadatas.controls[key].get('id').setValue(val[key].id)
      metadatas.controls[key].get(val[key].id).setValue(val[key].field_value)
      metadatas.controls[key].get('keyword').setValue(val[key].keyword)
      metadatas.controls[key].get('keyword_label').setValue(val[key].keyword_label)  
    }
    console.log(this.metadataForm.controls.metadatadetails);
    
    this.metadataForm.controls.metadatadetails.updateValueAndValidity();
    console.log(this.metadataForm)
  }

  getMetadataDetails() {
    console.log(this.metadataForm.getRawValue())
    const dataArray = [
      {
        file_id: this.file_Id,
        meta: this.metadataId,
        ...this.metadataForm.getRawValue()
      }
    ];
    console.log(this.NometadataAdded);
      if (this.NometadataAdded) {
        var entityType = '';
      } else {
        var entityType = '1';
      }
      let data = {
        metadata:dataArray,
        entityType: entityType
      }
      this.ngxUiLoaderService.start();
      this.api.saveUploaddetails(data).subscribe(data=>{
      console.log(data);
      this.UpdateMetadataResponse = data;
      if(this.UpdateMetadataResponse.status == 200){
        this.ngxUiLoaderService.stop()
        this.snackBar.open(this.UpdateMetadataResponse.message, "Dismiss", {
          duration:3000,
        })
        this.cancelButton();
        
      }else{
        this.ngxUiLoaderService.stop()
        this.snackBar.open(this.UpdateMetadataResponse.message, "Dismiss", {
          duration:3000,
        })
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
        
      });
  }

  enableSelect(){
    this.isDisabled = false;
    this.isDatePickerDisabled = false;
    this.isInputDisabled = false;
    this.isReadOnly = !this.isReadOnly;
    this.isButtonHidden = true;
    this.isButtonHiddenCancel = false;
    this.isButtonHiddenSubmitt = false;
  }
  cancelButton(){
    this.isDisabled = true;
    this.isDatePickerDisabled = true;
    this.isInputDisabled = true;
    this.isReadOnly = true;
    this.isButtonHidden = false;
    this.isButtonHiddenCancel = true;
    this.isButtonHiddenSubmitt = true;
  }


  onSelectionChangee(event, key: any, preview=null) {
    console.log(event)
    this.keyHide = false;
    this.gettemplateDataa(event, key, preview);
  }

  gettemplateDataa(value, val, preview) {
    console.log(preview)
    this.ngxUiLoaderService.start();
    this.api.gettemplateData(value).subscribe(data=>{
      console.log(data);
      this.templateresponse = data;
      if(this.templateresponse.status == 200){
        this.ngxUiLoaderService.stop();
        console.log(this.templateresponse.template_details);
        this.templateDetails = this.templateresponse.template_details.keyword;
        console.log(this.templateDetails);
        
        this.updateMetadetailsPreview(this.templateDetails, preview);
      
      } else {
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.templateresponse.message, "Dismiss", {
          duration:3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
  }

  openNav() {
    $("#mySidenav").css("width", "305px");
    // document.getElementById("section_all").style.marginRight = "0px";	  
  }
  renamee() {
    this.close1();
    var data = {
      entity_type: 3,
      id: this.project_Id,
      data: this.project
    }
    console.log(data);
    this.modalService.openModal(data, 'project');
  }

  // deleteModal(){
  //   console.log('clicked');
  //   $('#deleteModal3').modal('show');
  //   this.close()
  // }
  getPriority(){
    this.api.getPrioritydata().subscribe(data=>{
      console.log(data);
      this.priorityResponse = data;
      if(this.priorityResponse.status == 200){
        console.log(this.priorityResponse);
        this.priority = this.priorityResponse.result;
        
      }else{
        this.ngxUiLoaderService.stop();
      }
      
    }, error => {
      this.ngxUiLoaderService.stop();
    })
  }

  onKey(value) { 
    this.templateList = this.search(value);
  }
    
  search(value: string) { 
    let filter = value.toLowerCase();
    return this.allList.filter(option => option.template_name.toLowerCase().startsWith(filter));
  }

  toggleCheckboxVisibility() {
    this.close();
    this.close1();
    this.closeProject();
    this.projectFilesID = [];
    this.selectedItems = [];
    this.showCheckBox = !this.showCheckBox;
    if(!this.showCheckBox){
      console.log('qwertyyyyy');
      
      this.isSelected = false;
      if (Array.isArray(this.serchedDatas)) {
        this.serchedDatas.forEach(file => {
          file.selected = false;
        });
      }
  
      if (Array.isArray(this.serchedDataFolder)) {
        this.serchedDataFolder.forEach(file => {
          file.selected = false;
        });
      }
      if (Array.isArray(this.serchedDataProject)) {
        this.serchedDataProject.forEach(file => {
          file.selected = false;
        });
      }
  
      if (Array.isArray(this.filteredData)) {
        this.filteredData.forEach(file => {
          file.selected = false;
        });
      }
      if (Array.isArray(this.filterProjects)) {
        this.filterProjects.forEach(file => {
          file.selected = false;
        });
      }
  
      if (Array.isArray(this.filterProjects)) {
        this.filterProjects.forEach(file => {
          file.selected = false;
        });
      }
    }
    console.log('uuuuu');
  }

  bulkDelete(e, fileid, ind=null,data) {
    console.log(data);
    
    this.close1();
    
    if(data.project_time_exceeded == 1 && (this.showCheckBox)){
      return
    }

    this.filemove = true;
    console.log("ind",ind,e)
    
    

    this.selectedFilesId = fileid;
    console.log(this.selectedFilesId,'adich keriva');
    if (e.target.checked) {
      console.log(e.target.checked);
      this.projectFilesID.push(fileid);
      const hasPRUUID = this.projectFilesID.some(uuid => uuid.startsWith('PRJ'));
      console.log(hasPRUUID);
      if (hasPRUUID) {
        console.log('At least one UUID starts with "pr"');
        this.filemove = false;
      }
      console.log(this.projectFilesID);
      

    } else {
      this.projectFilesID = this.projectFilesID.filter(id => id !== fileid);
    }
    this.toggleSelection(ind);
  }

  toggleSelection(ind) {
    console.log(ind)
    const existingIndex = this.selectedItems.indexOf(ind);
    if (existingIndex !== -1) {
      this.selectedItems.splice(existingIndex, 1);
    } else {
      this.selectedItems.push(ind);
    }
  }

  openFolder(data,timeline,checkout_status,checkout_access) {
    console.log(data.uuid,data)
    if(timeline == 1){

      if (!this.showCheckBox) {
        console.log(timeline);
        console.log(data.uuid);
        this.file_Id = [data.uuid];
        this.folderdetailsdata = data;
        $('#timelineModal_open').modal('show');
      }
      
    }else{
      console.log('vayo');
      console.log(checkout_status,checkout_access);
      
      if (!this.showCheckBox) {
        if(checkout_status == 1 && checkout_access == 0){
          // alert('gsgdsagdvsg')
        }else{
          this.router.navigate(['/folders', data.uuid])
        }
        
      }
    }
  }
  updatefolderTimeline(edit = null){
    $('#timelineModal_open').modal('hide');
    if (edit == '1') {
      this.folderdetailsdata = this.folder;
    }
    console.log(this.folderdetailsdata);
    console.log(this.folderdetailsdata.start_date);
    this.todayDate = this.folderdetailsdata.start_date;

    this.timelineForm.get('name').setValue(this.folderdetailsdata.name);
    this.timelineForm.get('folderuuid').setValue(this.folderdetailsdata.uuid);
    this.timelineForm.get('start_date').setValue(this.folderdetailsdata.start_date);
    this.timelineForm.get('end_date').setValue(this.folderdetailsdata.end_date);
    $('#folderEdit_modal').modal('show');
    
  }

  convertOwl(str) {
    if(str == null)
          return str;
      var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      
      date = str.toString().split(" ");
      if(date.length == 1)
        return str;
      else
        return [date[3], mnths[date[1]], date[2]].join("-");
    }


  updateTimelinefolder(){
    let value= this.timelineForm.value;
    console.log(moment(this.timelineForm.value.end_date, 'MM/DD/YYYY',true).isValid())
    console.log(Date.parse(this.timelineForm.value.end_date))
    if (moment(this.timelineForm.value.end_date, 'MM/DD/YYYY',true).isValid()) {
      value['end_date'] = this.convertOwl(this.timelineForm.value.end_date);
    } 
    if (moment(this.timelineForm.value.start_date, 'MM/DD/YYYY',true).isValid()) {
      value['start_date'] = this.convertOwl(this.timelineForm.value.start_date);
    } 
    console.log( this.timelineForm.value);

    let data = this.timelineForm.value;
    this.ngxUiLoaderService.start();
    this.api.updateFolderTimeline(data).subscribe(data=>{
      console.log(data);
      this.timelineResponse = data;
      if(this.timelineResponse.status == 200){
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.timelineResponse.message, "Dismiss", {
          duration: 3000,
        })
        this.submit_handler('inputSearch');
        $('#folderEdit_modal').modal('hide');
        this.applyFilters();
        // this.submit_handler('inputSearch');
      }else{
        this.ngxUiLoaderService.stop();
        this.snackBar.open(this.timelineResponse.message, "Dismiss", {
          duration: 3000,
        })
      }
    }, error => {
      this.ngxUiLoaderService.stop();
    });
    
  }

  closeTimelinemodal(){
    $('#folderEdit_modal').modal('hide');
  }
  selectAll() {
    console.log('dsfdfs');
    this.isSelected = !this.isSelected;
    

    if (Array.isArray(this.serchedDatas)) {
      this.serchedDatas.forEach(file => {
        console.log(file);
        if (this.isSelected == false) {
          return
        }
        file.selected = true;
        this.projectFilesID.push(file.uuid);
        console.log(this.projectFilesID);
      })
    }

    if (Array.isArray(this.serchedDataFolder)) {
      this.serchedDataFolder.forEach(file => {
        console.log(file);
        
        file.selected = true;
        this.projectFilesID.push(file.uuid);
      });
    }
    if (Array.isArray(this.serchedDataProject)) {
      this.serchedDataProject.forEach(file => {
        console.log(file);
        file.selected = true;
        this.projectFilesID.push(file.uuid);
      });
    }
    if (Array.isArray(this.filteredData)) {
      this.filteredData.forEach(file => {
        console.log(file);
        file.selected = true;
        this.projectFilesID.push(file.uuid);
      });
    }
    if (Array.isArray(this.filterProjects)) {
      this.filterProjects.forEach(file => {
        console.log(file);
        file.selected = true;
        this.projectFilesID.push(file.uuid);
      });
    }
    if (Array.isArray(this.filterFolder)) {
      this.filterFolder.forEach(file => {
        console.log(file);
        file.selected = true;
        this.projectFilesID.push(file.uuid);
      });
    }
    console.log(this.projectFilesID);
  }

  deselectAll() {
    this.isSelected = false;
    this.showCheckBox = false;
    this.projectFilesID = [];

    if (Array.isArray(this.serchedDatas)) {
      this.serchedDatas.forEach(file => {
        file.selected = false;
      });
    }

    if (Array.isArray(this.serchedDataFolder)) {
      this.serchedDataFolder.forEach(file => {
        file.selected = false;
      });
    }
    if (Array.isArray(this.serchedDataProject)) {
      this.serchedDataProject.forEach(file => {
        file.selected = false;
      });
    }

    if (Array.isArray(this.filteredData)) {
      this.filteredData.forEach(file => {
        file.selected = false;
      });
    }
    if (Array.isArray(this.filterProjects)) {
      this.filterProjects.forEach(file => {
        file.selected = false;
      });
    }

    if (Array.isArray(this.filterProjects)) {
      this.filterProjects.forEach(file => {
        file.selected = false;
      });
    }

    console.log(this.projectFilesID);
  }
  checkoutModal(value = null){
    this.close1();
    this.closeProject();
    let data:any = {
      entity_type: 7,
      id: this.folder_uuid,
      name: this.shareName
    }
    if(value ){
      data.checkin = '2'
    }
    console.log(data)
    this.modalService.openModal(data ,'modalsearch');
  }
  
  handleCheckboxClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  downloadFile(){
    console.log('hello');
    let data ={
      file_id:this.get_fileId
    }
    this.api.downloadFile(data).subscribe(data =>{
      console.log(data);
      this.downloadResponse = data;
      if(this.downloadResponse.status == 200){
        this.path_urlDownload =this.downloadResponse.path;
        this.path_name =this.downloadResponse.name;
        console.log(this.path_urlDownload);
        
        this.fileDownloadService.downloadFile(this.path_urlDownload, this.path_name);
      }
    })
    
  }

}
