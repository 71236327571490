<div class="modal fade" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title move_header_title">{{'Move' | translate }} "{{nameMove}}"</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <mat-horizontal-stepper #stepper>
          <mat-step *ngFor="let step of responseMove; let i = index">
            <ng-template matStepLabel>{{ step.name }}</ng-template>
            <!-- Step content here -->
            <!-- <div *ngFor="let item of step.children; let index = index" >
               {{ item.name }}
              </div> -->
            <mat-list>
              <mat-list-item *ngFor="let item of step.children; let index = index"
                [ngClass]="{'selected': isSelected(item), 'hover': isHovered(item)}" (click)="setSelected(item)"
                (mouseover)="setHovered(item)">
                <span class="target_name">{{ item.name }}</span> <button *ngIf="isSelected(item)"
                  (click)="move(item)">{{'Move' | translate}}</button><img
                  *ngIf="isSelected(item) && item.children.length != 0" (click)="addStep(item)"
                  src="assets/images/arrow_right1.png" />
              </mat-list-item>
            </mat-list>
            <div>
              <button id="backButton" class="btn btn-primary mt-3" mat-button *ngIf="i > 0"
                (click)="filterArray()">{{'Back' | translate}}</button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
        <!-- <mat-step [stepControl]="secondFormGroup" label="Fill out your address">
              <p>sdfdsfdsff</p>
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext>Next</button>
            </mat-step>
            <mat-step>
              <ng-template matStepLabel>Done</ng-template>
              <p>You are now done.</p>
              <div>
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button (click)="stepper.reset()">Reset</button>
              </div>
            </mat-step> -->
        <!-- </mat-horizontal-stepper> -->
      </div>

      <!-- Modal footer -->
      <div id="CloseID" class="modal-footer">
        <button id="closeButton" type="button" class="btn" data-bs-dismiss="modal">{{'Close' | translate}}</button>
        <button id="saveButton" type="button" class="btn btn-primary" (click)="move()"
          [disabled]="!selectedValue">{{'Move' | translate}}</button>
      </div>

    </div>
  </div>
</div>



<div class="modal fade" id="shareModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="shareModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal_header share_header">
        <h5 class="h5_head">{{'Share'| translate}} "{{nameShare}}"</h5>
        <button type="button" #closebutton class="btn-close" (click)="clossSharemodal()" aria-label="Close"></button>
      </div>
      <form [formGroup]="createForm">
        <div class="modal-body">
          <div class="modal_share">
            <div id="tagArea-id" class="tagArea">
              <tag-input id="tagId" (onAdd)="onItemAdded($event)" (onRemove)="onItemRemoved($event)"
                formControlName="email" secondaryPlaceholder="{{'Add People' | translate}}"
                placeholder="{{'Add People' | translate}}" [identifyBy]="'uuid'" [displayBy]="'name'">
                <tag-input-dropdown [autocompleteItems]="autocompleteItems" [appendToBody]="false" [identifyBy]="'uuid'"
                  [displayBy]="'display'" [showDropdownIfEmpty]="true" [dynamicUpdate]="false" zIndex="10000"
                  [focusFirstElement]="true">
                  <ng-template let-item="item">
                    <div id="userDetailsId" class="Rtable-cell name-cell d-flex">
                      <div id="proPicId" *ngIf="item.type === 'user'"><img src="{{item.profile_pic}}" alt=""></div>
                      <div id="proPicId" *ngIf="item.type === 'group'"><div class="groupIconMembersList" [ngStyle]="{'background-color': getColor(item)}">{{getShortName(item.name)}}  </div>
                        <!-- <img src="assets/images/arrow_right1.png" alt=""> -->
                      </div>
                      <div class="nameEmail-section">
                        <div *ngIf="item.type === 'user'" class="Rtable-cell--content name-content ms-2 shareName">
                          {{item.name}}</div>
                        <div *ngIf="item.type === 'user'" id="userEmail"
                          class="Rtable-cell--content replay-link-content ms-2">{{ item.email }}</div>
                        <div *ngIf="item.type === 'group'" class="Rtable-cell--content name-content ms-2 shareName">
                          {{item.name}}</div>
                      </div>
                    </div>
                  </ng-template>
                </tag-input-dropdown>

              </tag-input>
            </div>
            <div *ngIf="isSubmitted">
              <div class="error_message" *ngIf="users.length==0">{{'Email is required' | translate}}</div>
              <!-- <div class="error_message" *ngIf="j.email.errors.pattern">{{'Email should valid' | translate}}</div> -->
            </div>

            <!-- <div id="tagArea-id" class="tagArea">
            <input formControlName="email" type="email" matInput (input)="_filter(j.email.value)" [matAutocomplete]="auto" >
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of userlist" [value]="option.email">
                    <div class="Rtable-cell name-cell d-flex">
                        <div id="proPicId"><img src="{{option.profile_pic}}" alt=""></div>
                        <div class="nameEmail-section">
                            <div class="Rtable-cell--content name-content ms-2">{{option.name}}</div>
                            <div id="userEmail" class="Rtable-cell--content replay-link-content ms-2">{{option.email}}</div>
                        </div>
                    </div>
                </mat-option>
              </mat-autocomplete>
            </div> -->
            <!-- <div class="error_message" *ngIf="groupSubmitted && tagInput.arr.length == 0">
              {{ 'Project Tags is required' | translate }}
          </div> -->

            <div class="d-flex ownerInfo" *ngIf="sharedViewlist">
              <div class="d-flex">
                <img class="owner_pic" src="{{sharedViewlist.profile_pic}}" alt="">
                <div class="OwnerNameEmail">
                  <div class="text-1">{{sharedViewlist.owner_name}}</div>
                  <h6>{{sharedViewlist.email}}</h6>
                </div>
              </div>
              <h5 class="text-grey-18">{{'Owner(you)' | translate}}</h5>
            </div>

            <div id="shareUser_id">

              <div class="d-flex ownerInfo" *ngFor="let sharedUser of sharedUserViewlist">
                <div class="d-flex">
                  <img class="owner_pic" src="{{sharedUser.profile_pic}}" alt="">
                  <div class="OwnerNameEmail">
                    <div class="text-1">{{sharedUser.owner_name}}</div>
                    <h6>{{sharedUser.email}}</h6>
                  </div>
                </div>
                <div class="unshare_butnDiv" (click)="unShare(sharedUser.file_id ,sharedUser.uuid)">
                  <button class="btn btn-primary"><i class="fa-solid fa-xmark"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-1">
            <div class="text-1">{{'Give access to' | translate}}</div>
            <div class="d-flex ownerInfo accessInfo">
              <div class="d-flex align-items-center">
                <img class="owner_pic" src="assets/images/addPeople.png" alt="">

                <select class="text-2" formControlName="limit" (change)="onSelectAccess($event.target.value)">
                  <option value="" disabled hidden selected>{{'Select access to' | translate}}</option>
                  <option value="0">{{'Any one with the link can view' | translate}}</option>
                  <option value="1">{{'Restricted' | translate}}</option>
                </select>

              </div>
              <a class="tooltip-test" title="Tooltip" (click)="getLink()"><img src="assets/images/link_sm.png"
                  alt=""></a>
            </div>
          </div>
          <div>
            <div class="input_style mt-3">
              <div class="input_style mt-4">
                <label for="exampleInputEmail" class="titleNAME">{{'Comments' | translate}}</label>
                <textarea name="" class="" id="" cols="30" rows="3" placeholder="{{'Comments' | translate}}"
                  formControlName="comment"></textarea>
              </div>
            </div>
          </div>


        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Understood</button>
        </div> -->

        <div class="modal_button_id"> <button type="submit" class="btn-primary btn-style"
            (click)="onSubmitModal()">{{'Done' | translate}}</button></div>
      </form>
    </div>
  </div>
</div>



<div class="modal fade" id="renameCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="renameCreateModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal_header">
        <h5>{{'Rename' | translate}}</h5>
        <button type="button" #closebutton class="btn-close" (click)="clossRenamemodal()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div id="folderName_And_Tagsection">

          <form [formGroup]="renameForm">
            <div class="modal-body">
              <div class="input_style mt-4">
                <label for="exampleInputEmail1">{{'Rename' | translate}}</label>
                <input class="input" type="text" aria-describedby="emailHelp" placeholder="rename.jpg"
                  formControlName="newfile_name" maxlength="32">
                <div *ngIf="renameSubmitted && a.newfile_name.errors">
                  <div class="error_message  text-danger" *ngIf="a.newfile_name.errors.required">{{'Name is required' |
                    translate}}</div>
                  <div class="error_message" *ngIf="a.newfile_name.errors.pattern">{{'Name' | translate}} {{'should valid' | translate}}</div>
                  <div class="error_message" *ngIf="a.newfile_name.errors.maxlength">{{'Name' | translate}} {{'should not be longer than 32 characters' | translate}}</div>
                </div>
              </div>

              <div class="input_style mt-5" *ngIf="folder_code">
                <label for="exampleInputEmail1">{{'Folder code' | translate}}</label>
                <input class="input" type="text" aria-describedby="emailHelp" placeholder="{{'Code' | translate}}"
                  formControlName="folder_code" maxlength="30">
                <div *ngIf="renameSubmitted && a.folder_code.errors">
                  <div class="error_message  text-danger" *ngIf="a.folder_code.errors.required">{{'Folder code' |
                    translate}} {{'is required' | translate}}</div>
                  <div class="error_message" *ngIf="a.folder_code.errors.pattern">{{'Folder code' | translate}} {{'shoud valid' | translate}}</div>
                </div>
              </div>

            </div>
            <div class="renameFileorFolder">
              <button type="button" class="btn_light text-primary renameBtn" (click)="clossRenamemodal()">{{'Cancel' |
                translate}}</button>
              <button class="btn_primary bg_blue renameBtn2" type="submit" (click)="projectrenamefileorfolder()">{{'Ok'
                | translate}}</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="previewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="renameCreateModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal_header">
        <!-- <img class="logo mt-4" routerLink="/dashboard" src="assets/images/logo.png" alt=""> -->
        <h5>{{'Preview' | translate}}</h5>
        <button type="button" #closebutton class="btn-close" (click)="clossmodal()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div id="folderName_And_Tagsection">

          <div class="modal-body">
            <h4 *ngIf="filename" class="filename">{{filename}}</h4>
            <div class="prievew_div">
            <div class="input_style mt-4">
              <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
              <ngx-doc-viewer googleCheckContentLoaded="true" *ngIf="fileExt && ( fileExt.file_type == 1)"
                googleCheckInterval=100 googleMaxChecks=5 [url]="image" viewer="google" style="width:100%;height:93vh;"
                (loaded)="onLoad($event, 1)"></ngx-doc-viewer>
              <!-- <iframe [src]="image" width="100%" height="600px" (load)="onLoad()" *ngIf="fileExt && fileExt == 'pdf'"></iframe> -->
              <div *ngIf="fileExt && ( fileExt.file_type == 2)">
                <img [src]="image" class="img-fluid" (load)="onLoad($event)" />
              </div>
              <div *ngIf="fileExt && ( fileExt.file_type == 3)">
                <video controls autoplay="autoplay" class="w-100" (loadedmetadata)="onLoad($event)" [src]="image">
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="nextAndPreviousButtons">
                <!-- <i class="fa-solid fa-chevron-left"></i><i class="fa-solid fa-chevron-right"></i> -->
                <button class="btn" (click)="previousFile()" [disabled]="currentIndex == 0"><i class="fa-sharp-duotone fa-solid fa-arrow-left"></i>  {{'Previous' | translate}}</button>
                <button class="btn" (click)="nextFile()" [disabled]="currentIndex == fileList.length-1">{{'Next' | translate}}  <i class="fa-sharp-duotone fa-solid fa-arrow-right"></i></button>
              </div>
            </div>
            <!-- details-flex-div -->
            <div class="mt-4 preview_right_div">
              <div class="details-div ">
                <div class="grid" *ngIf="dataDetials">
                  <div class="grid-child">
                    <h5 class="black">{{'Saved location' | translate}}</h5>
                  </div>
                  <div class="grid-child">
                    <h5 class="text-capitalise">{{dataDetials.saved_location}}</h5>
                  </div>
                  <div class="grid-child">
                    <h5 class="black">{{'Size' | translate}}</h5>
                  </div>
                  <div class="grid-child">
                    <h5 class="text-capitalise">{{dataDetials.file_size}}</h5>
                  </div>
                  <div class="grid-child">
                    <h5 class="black">{{'Modified' | translate}}</h5>
                  </div>
                  <div class="grid-child">
                    <h5 class="text-capitalise">{{dataDetials.modified}}</h5>
                  </div>
                  <div class="grid-child">
                    <h5 class="black">{{'Type' | translate}}</h5>
                  </div>
                  <div class="grid-child">
                    <h5 class="text-capitalise">{{dataDetials.type}}</h5>
                  </div>
                  <div class="grid-child">
                    <h5 class="black">{{'Timeline' | translate}}</h5>
                  </div>
                  <div class="grid-child">
                    <h5 class="text-capitalise">{{dataDetials.start_date}} &nbsp; - &nbsp; {{dataDetials.end_date}}</h5>
                  </div>
                </div>
              </div>
              <div class="metadata-first-div ">
                <div class="metadata-sec" *ngIf="metadatName">
                  <div class="metadata-style">
                    <div class="">
                      <h5 class="black">{{'Metadata' | translate}}</h5>
                    </div>
                    <div class="no-border-metadataName">
                      <h4>{{metadatName}}</h4>
                    </div>
                  </div>
  
                  <div class="align-items-center" *ngFor="let data of metadata">
                    <div class="bottom-padd black">{{data.keyword_label}}</div>
                    <div class="border-filedvalue">{{data.field_value}} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          </div>
          


          <div class="renameFileorFolder">
            <!-- <button type="button" class="btn_light text-primary renameBtn" (click)="clossRenamemodal()">{{'Cancel' | translate}}</button> -->
            <button class="btn_primary bg_blue renameBtn2" type="submit" (click)="clossmodal()">{{'Ok' |
              translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="createProjectModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="addUserLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalToggleLabel">{{'Signature Request' | translate}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeGroupmodal()"></button>
      </div>

      <form>
        <div class="modal-body">
          <div class="title mt-4 mb-2">{{'Request To' | translate}}</div>
          <div class="membersList">
            <div class="members_container">
              <div class="members" *ngFor="let member of grouMembers; let i = index">
                <div><img class="userImageadd" [src]="member.profile_pic" alt="">{{member.name}}</div>
                <img class="closeIconuser" src="assets/images/close_sm.png" alt=""
                  (click)="removeUser(i, member.uuid, member)">
              </div>
            </div>
          </div>
          <div *ngIf="groupSubmitted && grouMembers.length == 0">
            <div class="error_message text-center">{{'Group Members is required' | translate}}</div>
          </div>

          <div class="title mt-4 mb-2">{{'Add Users' | translate}}</div>
          <div class="addUserList">
            <div class="groups">
              <div class="list" *ngFor="let item of userlistdataall">
                <div class="logoArea">
                  <img class="userImageListing" src="{{ item.profile_pic }}" alt="">
                  <div>{{ item.name }} <span>{{ item.email }}</span></div>
                </div>
                <button class="btn_primary bg_blue" type="button" (click)="adduser(item)">{{'Add' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!-- data-bs-toggle="modal" href="" -->
          <button class="btn_primary bg_blue" type="button" (click)="ngSubmitrequestSugnature()">{{'Send Request' |
            translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>



<div class="modal fade" id="createArchieveModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="shareModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal_header">
        <h5>{{'Archive' | translate}}</h5>
        <button type="button" #closebutton class="btn-close" (click)="clossArchievemodal()" aria-label="Close"></button>
      </div>
      <form [formGroup]="createArchieveForm">
        <div class="modal-body">
          <div>
            <div class="gro_name-container">
              <div class="title mt-3 titleNAME">{{'Archived folder Name' | translate}}</div>
              <input type="text" class="archieve_folderName" formControlName="name"
                placeholder="{{'folder Name' | translate}}" maxlength="32">
            </div>
          </div>
          <div *ngIf="groupSubmitted && b.name.errors">
            <div class="error_message " *ngIf="b.name.errors.required">{{'Folder name' | translate}} {{'is required' |
              translate}}</div>
          </div>

          <div class="title mt-3 titleNAME">{{'Add Users' | translate}}</div>
          <div id="tagArea-id" class="tagArea ">
            <tag-input id="tagId" (onAdd)="onItemAdded($event)" (onRemove)="onItemRemoved($event)"
              formControlName="users" secondaryPlaceholder="{{'Add People' | translate}}"
              placeholder="{{'Add People' | translate}}" [identifyBy]="'uuid'" [displayBy]="'name'">
              <tag-input-dropdown [autocompleteItems]="userlist" [appendToBody]="false" [identifyBy]="'uuid'"
                [displayBy]="'email'" [showDropdownIfEmpty]="true" [dynamicUpdate]="false" zIndex="10000"
                [focusFirstElement]="true">
                <ng-template let-item="item">
                  <div id="userDetailsId" class="Rtable-cell name-cell d-flex">
                    <div id="proPicId"><img src="{{item.profile_pic}}" alt=""></div>
                    <div class="nameEmail-section">
                      <div class="Rtable-cell--content name-content ms-2 shareName">{{item.name}}</div>
                      <div id="userEmail" class="Rtable-cell--content replay-link-content ms-2">{{ item.email }}</div>
                    </div>
                  </div>
                </ng-template>
              </tag-input-dropdown>
            </tag-input>
          </div>
          <div *ngIf="folderSubmitted">
            <div class="error_message" *ngIf="users.length==0">{{'User is required' | translate}}</div>
          </div>
          <div class="ownerInfo2" *ngIf="ownerUser">
            <div class="d-flex">
              <img class="owner_pic" src="{{ownerUser.admin_profile}}" alt="">
              <div class="OwnerNameEmail">
                <div class="text-1">{{ownerUser.admin_name}}</div>
                <h6>{{ownerUser.admin_email}}</h6>
              </div>
            </div>
            <h5 class="text-grey-18">{{'Admin' | translate}}</h5>
          </div>

          <div class="input_style mt-4">
            <label for="exampleInputEmail" class="titleNAME">{{'Description' | translate}}</label>
            <textarea name="" class="" id="" cols="30" rows="5" placeholder="{{'Description' | translate}}"
              formControlName="comment"></textarea>
          </div>
          <div *ngIf="groupSubmitted && b.comment.errors" class="m-2">
            <div class="error_message " *ngIf="b.comment.errors.required">{{'Description' | translate}} {{'is required'
              | translate}}</div>
            <div class="error_message" *ngIf="b.comment.errors.pattern">{{'Description' | translate}} {{'shoud valid' |
              translate}}</div>
          </div>

        </div>
        <!-- <div class="modal_button_id"> <button type="submit" class="btn btn-danger btn-style"
            (click)="onSubmitArchieveModal()">{{'Archieve' | translate}}</button></div> -->
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal" aria-label="Close"
            (click)="clossArchievemodal()">{{'Cancel' | translate}}</button>
          <button id="button-id" class="btn_primary bg_red" (click)="onSubmitArchieveModal()">{{'Archive' |
            translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="modal fade" id="CheckoutModal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="CheckoutModalLabel" aria-hidden="true">
  <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal_header">
          <h5>{{nameEdit ? ('Check out' | translate) : ('Check in' | translate) }}</h5>
        <button type="button" #closebutton class="btn-close" (click)="closeCheckoutmodal()"></button>
      </div>
      <form [formGroup]="checkOutForm">
      <div class="modal-body">
        <div class="container_input_div" *ngIf="dateCheck">
          <div class="input_style mt-4 d-flex align-items-center">
              <div class="datePicker-modal">
                  <div class="dateDiv">
                      <label for="">{{'Return date' | translate}}</label>
                      <mat-form-field class="example-full-width" appearance="none">
                          <input matInput [matDatepicker]="dp10"  [min]="todayDate" [matDatepicker]="picker" readonly   formControlName="return_date">
                          <mat-datepicker-toggle matIconSuffix [for]="dp10"></mat-datepicker-toggle>
                          <mat-datepicker #dp10></mat-datepicker>
                      </mat-form-field>
                  </div>
              </div>
          </div>
        </div>
        <div>
          <div class="input_style mt-3">
            <div class="input_style mt-4">
              <label for="exampleInputEmail" class="titleNAME">{{'Comments' | translate}}</label>
              <textarea name="" class="" id="" cols="30" rows="3" placeholder="{{'Comments' | translate}}"
                formControlName="comment"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div id="delete_id" class="btnGrp mt-4">
        <button id="button-id" class="btn_primary bg_red" (click)="closeCheckoutmodal()">{{'Cancel' | translate}}</button>
        <button id="button-id" class="btn_primary bg_blue "(click)="checkOut()">{{buttonCheck ? ('Check out' | translate) : ('Check in' | translate)}}</button>
      </div>
    </form>    
    </div>
  </div>
</div>

